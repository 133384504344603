import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import {useMaterialUIController} from "../../../../context";

function File({ filename, date, fileType, isFolder, size, currentPath, onMessage }) {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const downloadButtonClick = () => {
    onMessage({ event: "action", type: "GET", args: [currentPath, filename] });
  };

  const deleteButtonClick = () => {
    onMessage({ event: "action", type: "DELETE", args: [`${currentPath}/${filename}`] });
  };

  const gotoFolder = () => {
    onMessage({ event: "action", type: "LIST", args: [currentPath, filename] });
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={0}
    >
      <Grid container direction="row" spacing={0} columns={16} m={1}>
        <Grid item xs={6}>
          <MDBox display="flex" alignItems="center">
            <Icon fontSize="small" sx={{ color: darkMode ? "#ffffff" : "#000" }}>{isFolder ? "folder" : "description"}</Icon>
            <MDButton size="small" sx={{ textTransform: "none", color: darkMode ? "#ffffff" : "#000" }} variant="transparent" ml={10} onClick={gotoFolder}>
              {filename}
            </MDButton>
          </MDBox>
        </Grid>
        <Grid item xs={2}>
          <MDTypography variant="button" fontWeight="regular" color="text">
            {fileType}
          </MDTypography>
        </Grid>
        <Grid item xs={2}>
          <MDTypography variant="button" fontWeight="regular" color="text" mr={4}>
            {size}
          </MDTypography>
        </Grid>
        <Grid item xs={4}>
          <MDTypography variant="button" fontWeight="regular" color="text">
            {date}
          </MDTypography>
        </Grid>
        <Grid item xs={1}>
          {isFolder || size === "0 B" ? null : (
            <MDBox display="flex" sx={{ cursor: "pointer" }}>
              <Icon fontSize="medium" onClick={downloadButtonClick} sx={{ color: darkMode ? "#ffffff" : "#000" }}>
                file_download
              </Icon>
            </MDBox>
          )}
        </Grid>
        <Grid item xs={1}>
        {isFolder || size === "0 B" ? null : (
            <MDBox display="flex" sx={{ cursor: "pointer" }}>
              <Icon fontSize="medium" onClick={deleteButtonClick} sx={{ color: "#ff0000" }}>
                delete
              </Icon>
            </MDBox>
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

File.defaultProps = {
  noGutter: false,
};

File.propTypes = {
  filename: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isFolder: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
  onMessage: PropTypes.func,
  noGutter: PropTypes.bool,
};

export default File;
