import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../components/MDButton";
import PropTypes from "prop-types";
import SelfDelete from "./selfDelete";
import Tooltip from "@mui/material/Tooltip";

function ControlPanel({ clientId }) {
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Commands
          </MDTypography>
          <Grid container spacing={2} direction="row">
            <Grid item>
              <Grid container spacing={2} direction="column">
                <Grid item xs={12} md={6} lg={4}>
                  <MDButton
                    component="a"
                    href={`/clients/${clientId}/messages`}
                    variant="outlined"
                    color="info"
                    size="medium"
                    sx={{ width: "100%" }}
                  >
                    Messages
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDButton
                    component="a"
                    href={`/clients/${clientId}/applications`}
                    variant="outlined"
                    color="info"
                    size="medium"
                    sx={{ width: "100%" }}
                  >
                    Applications
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                  <MDButton
                    component="a"
                    href={`/clients/${clientId}/keylogger`}
                    variant="outlined"
                    color="info"
                    size="medium"
                    sx={{ width: "100%" }}
                  >
                    Keylogger
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDButton
                    component="a"
                    href={`/clients/${clientId}/contacts`}
                    variant="outlined"
                    color="info"
                    size="medium"
                    sx={{ width: "100%" }}
                  >
                    Contacts
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDButton
                    component="a"
                    href={`/clients/${clientId}/reader`}
                    variant="outlined"
                    color="info"
                    size="medium"
                    sx={{ width: "100%" }}
                  >
                    Screen Reader
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDButton
                    component="a"
                    href={`/clients/${clientId}/files`}
                    variant="outlined"
                    color="info"
                    size="medium"
                    sx={{ width: "100%" }}
                  >
                    File Manager
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <SelfDelete clientId={clientId} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2} direction="column">
                <Grid item xs={12} md={6} lg={4}>
                  <MDButton
                    component="a"
                    href={`/clients/${clientId}/multicontrol`}
                    variant="outlined"
                    color="info"
                    size="medium"
                    sx={{ width: "100%" }}
                  >
                    Multicontrol
                  </MDButton>
                </Grid>
                <Tooltip title="Coming soon" placement="right">
                  <Grid item xs={12} md={6} lg={4}>

                    <MDButton
                      component="a"
                      href={`/clients/${clientId}/multicontrol`}
                      variant="outlined"
                      color="info"
                      size="medium"
                      sx={{
                        width: "100%",
                        opacity: 0.5,
                        '&.Mui-disabled': {
                          backgroundColor: 'rgba(255, 255, 255, 0.12)',
                          color: 'rgba(255, 255, 255, 0.5)',
                          borderColor: 'rgba(255, 255, 255, 0.5)',
                        }
                      }}
                      disabled
                    >
                      Permissions Center
                    </MDButton>
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

ControlPanel.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default ControlPanel;
