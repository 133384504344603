import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-signin2.png";
import axios from "axios";

function Basic() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [tfaStatus, setTfaStatus] = useState(null);
  const [totpCode, setTotpCode] = useState("");
  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/sign_in`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setTfaStatus(response.data.tfa_status);

      const jwtToken = response.headers["authorization"];
      if (jwtToken) {
        const parts = jwtToken.split(" ");
        const token = parts[1];
        localStorage.setItem("_token", token);
      }

      if (!response.data.tfa_status) {
        navigate("/clients");
      }
    } catch (error) {
      console.error("Sign-in error:", error);
    }
  };

  const handleTfaSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("totp", totpCode);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/verify_totp`,
        formData,
        {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem('_token')}`
          },
        }
      );

      if (response.status === 200) {
        navigate("/clients");
      }
    } catch (error) {
      console.error("TFA verification error:", error);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        {
          tfaStatus === null ? (
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Username"
                    fullWidth
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type='password'
                    label="Password"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSignIn();
                      }
                    }}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" fullWidth onClick={handleSignIn}>
                    sign in
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          ) : (
            <MDBox pt={4} pb={3} px={3}>
              <MDBox mb={2}>
                <MDInput
                  type="number"
                  label="Enter TOTP"
                  fullWidth
                  value={totpCode}
                  onChange={(e) => setTotpCode(e.target.value)}
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" fullWidth onClick={handleTfaSubmit}>
                    verify TOTP
                  </MDButton>
                </MDBox>
            </MDBox>
          )
        }
      </Card>
    </BasicLayout>
  );
}

export default Basic;
