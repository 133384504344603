import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import Dropdown from "layouts/actions/applications/components/Dropdown";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AllApplications from "./allApplications";
import BanksApplications from "./banksApplications";
import CryptoApplications from "./cryptoApplications";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("_token");
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/token`, {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const jwtToken = response.headers["authorization"];

                if (jwtToken) {
                    const parts = jwtToken.split(" ");
                    const token = parts[1];
                    localStorage.setItem("_token", token);
                } else {
                    navigate("/sign-in");
                }
            } catch (error) {
                if (error.code === "ERR_NETWORK") {
                    enqueueSnackbar("No connection with server", {
                        variant: "error",
                        persist: true,
                        anchorOrigin: { horizontal: "center", vertical: "top" },
                    });
                } else if (error.response.status === 401) {
                    navigate("/sign-in");
                }
            }
        };
        fetchData();
    }, []);

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <MDBox sx={{ p: 1 }}>{children}</MDBox>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Applications({ clientId }) {
    const defaultTab = 1;
    const savedTab = parseInt(localStorage.getItem('activeTab'), 10);
    const [value, setValue] = useState(isNaN(savedTab) ? defaultTab : savedTab);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [allRows, setAllRows] = useState([]);
    const [banksRows, setBanksRows] = useState([]);
    const [cryptoRows, setCryptoRows] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem('activeTab', newValue);
    };

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("_token");
            try {
                await axios.post(`${process.env.REACT_APP_API_URL}/applications/update`,
                    {
                        client_id: clientId,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/applications/${clientId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                const jwtToken = response.headers["authorization"];
                if (jwtToken) {
                    const parts = jwtToken.split(" ");
                    const token = parts[1];
                    localStorage.setItem("_token", token);
                } else {
                    navigate("/sign-in");
                }

                const apps_data = response.data.applications;
                const banks_apps = response.data.banks_applications;
                const crypto_apps = response.data.crypto_applications;

                const formattedRows = apps_data.map((app) => ({
                    name: (
                        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
                            {app.name}
                        </MDTypography>
                    ),
                    action: <Dropdown clientId={clientId} packageName={app.package_name} />,
                }));

                const banksFormattedRows = banks_apps.map((app) => ({
                    name: (
                        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
                            {app.name}
                        </MDTypography>
                    ),
                    action: <Dropdown clientId={clientId} packageName={app.package_name} />,
                }));

                const cryptoFormattedRows = crypto_apps.map((app) => ({
                    name: (
                        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
                            {app.name}
                        </MDTypography>
                    ),
                    action: <Dropdown clientId={clientId} packageName={app.package_name} />,
                }));

                setAllRows(formattedRows);
                setBanksRows(banksFormattedRows);
                setCryptoRows(cryptoFormattedRows);

            } catch (error) {
                if (error.code === "ERR_NETWORK") {
                    enqueueSnackbar("No connection with server", {
                        variant: "error",
                        persist: true,
                        anchorOrigin: { horizontal: "center", vertical: "top" },
                    });
                } else if (error.response.status === 401) {
                    navigate("/sign-in");
                }
            }
        };
        fetchData();

        const intervalId = setInterval(fetchData, 2 * 60 * 1000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <Card>
            <MDBox mt={2} mb={1}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="info"
                    aria-label="tabs">
                    <Tab label="All" {...a11yProps(0)} />
                    <Tab label="Banks" {...a11yProps(1)} />
                    <Tab label="Crypto" {...a11yProps(2)} />
                </Tabs>
            </MDBox>
            <TabPanel value={value} index={0}>
                <AllApplications rows={allRows} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <BanksApplications rows={banksRows} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <CryptoApplications rows={cryptoRows} />
            </TabPanel>
        </Card>
    );
}

Applications.propTypes = {
    clientId: PropTypes.string.isRequired,
};

export default Applications;
