import {useState} from "react";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import axios from "axios";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";

// eslint-disable-next-line react/prop-types
function Dropdown({ clientId, packageName }) {
  const [dropdown, setDropdown] = useState(null);

  const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
  const closeDropdown = () => setDropdown(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const runApp = async () => {
    const token = localStorage.getItem("_token");
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/applications/start`, {
        client_id: clientId,
        package_name: packageName,
      },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      closeDropdown();
      const jwtToken = response.headers["authorization"];

      if (jwtToken) {
        const parts = jwtToken.split(" ");
        const token = parts[1];
        localStorage.setItem("_token", token);
      } else {
        navigate("/sign-in");
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
  };

  const stopApp = async () => {
    const token = localStorage.getItem("_token");
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/applications/stop`, {
        client_id: clientId,
        package_name: packageName,
      },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      closeDropdown();
      const jwtToken = response.headers["authorization"];

      if (jwtToken) {
        const parts = jwtToken.split(" ");
        const token = parts[1];
        localStorage.setItem("_token", token);
      } else {
        navigate("/sign-in");
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
  };

  const removeApp = async () => {
    const token = localStorage.getItem("_token");
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/applications/remove`, {
        client_id: clientId,
        package_name: packageName,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
      closeDropdown();
      const jwtToken = response.headers["authorization"];

      if (jwtToken) {
        const parts = jwtToken.split(" ");
        const token = parts[1];
        localStorage.setItem("_token", token);
      } else {
        navigate("/sign-in");
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
  };

  // Styles
  const iconStyles = {
    ml: 1,
    fontWeight: "bold",
    transition: "transform 200ms ease-in-out",
  };

  const dropdownIconStyles = {
    transform: dropdown ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };

  return (
    <MDBox>
      <MDButton variant="gradient" color="info" onClick={openDropdown}>
        Actions <Icon sx={dropdownIconStyles}>expand_more</Icon>
      </MDButton>
      <Menu anchorEl={dropdown} open={Boolean(dropdown)} onClose={closeDropdown}>
        <MenuItem onClick={runApp}>Run</MenuItem>
        <MenuItem onClick={stopApp}>Stop</MenuItem>
        <MenuItem onClick={removeApp}>Remove</MenuItem>
      </Menu>
    </MDBox>
  );
}

export default Dropdown;
