import {useState} from "react";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import MDTypography from "components/MDTypography";
import axios from "axios";

function File({ filename, date, size, clientId, noGutter }) {
  const [isFileLoading, setIsFileLoading] = useState(false);
  const navigate = useNavigate();

  const downloadButtonClick = async () => {
    setIsFileLoading(true);
    const token = localStorage.getItem("_token");
    const replacedString = filename.replace(/\//g, "____");
    const url = `${process.env.REACT_APP_API_URL}/file_manager/${clientId}/${replacedString}`;
    try {
      const response = await axios(
        {
          url: url,
          method: "GET",
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const blob = new Blob([response.data], { type: "application/octet-stream" });
      const link = document.createElement("a");
      const href = URL.createObjectURL(blob);

      link.href = href;
      const parts = replacedString.split("____");
      const lastElement = parts[parts.length - 1];
      link.setAttribute("download", lastElement);
      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(href);
      setIsFileLoading(false);
    } catch (error) {
      setIsFileLoading(false);
      if (error.code === "ERR_NETWORK") {
        return;
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
    >
      <MDBox lineHeight={1.125}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {filename}
        </MDTypography>
        <MDTypography variant="caption" fontWeight="regular" color="text">
          {date}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" alignItems="center">
        <MDTypography variant="button" fontWeight="regular" color="text" mr={5}>
          {size}
        </MDTypography>
        {size === "0 B" ? null : isFileLoading ? (
          <MDBox display="flex" sx={{ cursor: "pointer" }}>
            <CircularProgress />
          </MDBox>
        ) : (
          <MDBox display="flex" sx={{ cursor: "pointer" }}>
            <Icon fontSize="medium" onClick={downloadButtonClick}>
              file_download
            </Icon>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
}

File.defaultProps = {
  noGutter: false,
};

File.propTypes = {
  filename: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default File;
