import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "../../../examples/Tables/DataTable";
import { useSnackbar } from "notistack";
import Dropdown from "./Dropdown";
import MDButton from "../../../components/MDButton";

function Contacts() {
  const { clientId } = useParams();
  const [rows, setRows] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const columns = [
    { Header: "id", accessor: "id", align: "center" },
    { Header: "name", accessor: "name", align: "center" },
    { Header: "phone number", accessor: "phoneNumber", align: "center" },
    { Header: "region", accessor: "country", align: "center" },
    { Header: "carrier", accessor: "carrier", align: "center" },
    { Header: "timezone", accessor: "timezone", align: "center" },
    { Header: "zone name", accessor: "zoneName", align: "center" },
    { Header: "utc offset", accessor: "utcOffset", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  useEffect(() => {
    document.title = "Contacts | Gate";
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("_token");
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/contacts/${clientId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const jwtToken = response.headers["authorization"];
        if (jwtToken) {
          const parts = jwtToken.split(" ");
          const token = parts[1];
          localStorage.setItem("_token", token);
        } else {
          navigate("/sign-in");
        }

        const contacts_data = response.data.contacts;

        const formattedRows = contacts_data.map((contact) => ({
          id: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {contact.id}
            </MDTypography>
          ),
          name: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {contact.name}
            </MDTypography>
          ),
          phoneNumber: (
            <Grid container direction="column" key={contacts_data.id}>
              {contact.numbers.map((number, index) => (
                <Grid key={number}>
                  <MDTypography key={index} component="span" variant="caption" fontWeight="medium">
                    {number.phone_number}
                  </MDTypography>
                </Grid>
              ))}
            </Grid>
          ),
          country: (
            <Grid container direction="column" key={contacts_data.id}>
              {contact.numbers.map((number, index) => (
                <Grid key={number}>
                  <MDTypography key={index} component="span" variant="caption" fontWeight="medium" color={number.country === "Unknown" ? "warning" : "inherit"}>
                    {number.country}
                  </MDTypography>
                </Grid>
              ))}
            </Grid>
          ),
          carrier: (
            <Grid container direction="column" key={contacts_data.id}>
              {contact.numbers.map((number, index) => (
                <Grid key={number}>
                  <MDTypography key={index} component="span" variant="caption" fontWeight="medium" color={number.carrier === "Unknown" ? "warning" : "inherit"}>
                    {number.carrier}
                  </MDTypography>
                </Grid>
              ))}
            </Grid>
          ),
          timezone: (
            <Grid container direction="column" key={contacts_data.id}>
              {contact.numbers.map((number, index) => (
                <Grid key={number}>
                  <MDTypography key={index} component="span" variant="caption" fontWeight="medium" color={number.timezone === "Unknown" ? "warning" : "inherit"}>
                    {number.timezone}
                  </MDTypography>
                </Grid>
              ))}
            </Grid>
          ),
          zoneName: (
            <Grid container direction="column" key={contacts_data.id}>
              {contact.numbers.map((number, index) => (
                <Grid key={number}>
                  <MDTypography key={index} component="span" variant="caption" fontWeight="medium" color={number.zone_name === "Unknown" ? "warning" : "inherit"}>
                    {number.zone_name}
                  </MDTypography>
                </Grid>
              ))}
            </Grid>
          ),
          utcOffset: (
            <Grid container direction="column" key={contacts_data.id}>
              {contact.numbers.map((number, index) => (
                <Grid key={number}>
                  <MDTypography key={index} component="span" variant="caption" fontWeight="medium" color={number.utc_offset === "Unknown" ? "warning" : "inherit"}>
                    {number.utc_offset}
                  </MDTypography>
                </Grid>
              ))}
            </Grid>
          ),
          action: <Dropdown clientId={clientId} contactId={contact.id} />,
        }));

        setRows(formattedRows);
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          enqueueSnackbar("No connection with server", {
            variant: "error",
            persist: true,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          });
        } else if (error.response.status === 401) {
          navigate("/sign-in");
        }
      }
    };
    fetchData();

    const intervalId = setInterval(fetchData, 5 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  const createUpdateTask = async () => {
    const token = localStorage.getItem("_token");
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/contacts/update`,
        {
          client_id: clientId,
          sort: "ASC",
          limit: 500,
          offset: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
  };

  const createContact = () => {
    navigate(`/clients/${clientId}/contacts/creator`)
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={1}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Contacts
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={2.5}>
                <Grid container direction="row" spacing={3}>
                  <Grid item>
                    <MDButton variant="gradient" color="info" size="small" onClick={createUpdateTask}>
                      Update
                    </MDButton>
                  </Grid>
                  <Grid item>
                    <MDButton variant="gradient" color="info" size="small" onClick={createContact}>
                      Create
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Contacts;
