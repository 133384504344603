import Clients from "layouts/clients";
import Logs from "layouts/logs";
import Settings from "layouts/settings";
import Builder from "layouts/builder";
import Client from "layouts/clients/client";
import SignIn from "layouts/authentication/sign-in";
import Contacts from "layouts/actions/contacts";
import Keylogger from "layouts/actions/keylogger";
import Messages from "layouts/actions/messages";
import Thread from "layouts/actions/messages/chat";
import Reader from "layouts/actions/reader";
import FileManager from "layouts/actions/files";
import Applications from "layouts/actions/applications";
import Multicontrol from "layouts/actions/multicontrol";
import ContactEditor from "layouts/actions/contact_editor";
import ContactCreator from "layouts/actions/contact_creator";

import Icon from "@mui/material/Icon";


const routes = [
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    icon: <Icon fontSize="small">groups</Icon>,
    route: "/clients",
    component: <Clients />,
  },
  {
    type: "collapse",
    name: "Logs",
    key: "logs",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/logs",
    component: <Logs />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settings",
    component: <Settings />,
  },
  {
    type: "collapse",
    name: "Builder",
    key: "builder",
    icon: <Icon fontSize="small">handyman</Icon>,
    route: "/builder",
    component: <Builder />,
  },
  {
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    name: "Client",
    key: "client",
    route: "/clients/:clientId",
    component: <Client />,
  },
  {
    name: "Contacts",
    key: "contacts",
    route: "/clients/:clientId/contacts",
    component: <Contacts />,
  },
  {
    name: "Contacts Editor",
    key: "contacts_editor",
    route: "/clients/:clientId/contacts/:contactId",
    component: <ContactEditor />,
  },
  {
    name: "Contacts Editor",
    key: "contacts_editor",
    route: "/clients/:clientId/contacts/creator",
    component: <ContactCreator />,
  },
  {
    name: "Keylogger",
    key: "keylogger",
    route: "/clients/:clientId/keylogger",
    component: <Keylogger />,
  },
  {
    name: "Messages",
    key: "messages",
    route: "/clients/:clientId/messages",
    component: <Messages />,
  },
  {
    name: "Chat",
    key: "chat",
    route: "/clients/:clientId/messages/:chatId",
    component: <Thread />,
  },
  {
    name: "Reader",
    key: "reader",
    route: "/clients/:clientId/reader",
    component: <Reader />,
  },
  {
    name: "File Manager",
    key: "files",
    route: "/clients/:clientId/files",
    component: <FileManager />,
  },
  {
    name: "Applications",
    key: "applications",
    route: "/clients/:clientId/applications",
    component: <Applications />,
  },
  {
    name: "Multicontrol",
    key: "multicontrol",
    route: "/clients/:clientId/multicontrol",
    component: <Multicontrol />,
  },
];

export default routes;
