import {useNavigate, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {useEffect, useState} from "react";
import axios from "axios";
import MDButton from "../../../../components/MDButton";
import {useSnackbar} from "notistack";

function Thread() {
  const { clientId } = useParams();
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Messages ${clientId} | Gate`;
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("_token");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/messages/${clientId}/${chatId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
        );

        const jwtToken = response.headers["authorization"];

        if (jwtToken) {
          const parts = jwtToken.split(" ");
          const token = parts[1];
          localStorage.setItem("_token", token);
        } else {
          navigate("/sign-in");
        }

        const messages_data = response.data.messages;
        setMessages(messages_data);
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          enqueueSnackbar("No connection with server", {
            variant: "error",
            persist: true,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          });
        } else if (error.response.status === 401) {
          navigate("/sign-in");
        }
      }
    };
    fetchData();
  }, []);

  const createUpdateTask = async () => {
    const token = localStorage.getItem("_token");
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/messages/update/messages`, {
        client_id: clientId,
        sort: "DESC",
        limit: 10,
        offset: 0,
        thread_id: chatId,
      },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const jwtToken = response.headers["authorization"];

      if (jwtToken) {
        const parts = jwtToken.split(" ");
        const token = parts[1];
        localStorage.setItem("_token", token);
      } else {
        navigate("/sign-in");
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
  };

  const renderMessages = messages.map(({ address, body, date, type }) =>
    type === "2" ? (
      // eslint-disable-next-line react/jsx-key
      <MDBox pt={2} px={2}>
        <Grid container spacing={2} justifyContent="right">
          <MDAlert color="success" dismissible={false}>
            <Grid item container direction="column" spacing={2}>
              <Grid item xs>
                <MDTypography
                  component="span"
                  variant="caption"
                  color="inherit"
                  fontWeight="medium"
                >
                  {address}:
                </MDTypography>
                <MDTypography component="p" variant="caption" color="inherit">
                  {body}
                </MDTypography>
              </Grid>
              <Grid item>
                <MDTypography component="p" variant="caption" color="inherit">
                  {date}
                </MDTypography>
              </Grid>
            </Grid>
          </MDAlert>
        </Grid>
      </MDBox>
    ) : (
      // eslint-disable-next-line react/jsx-key
      <MDBox pt={2} px={2}>
        <Grid container spacing={2} justifyContent="left" ml={1} lg={8}>
          <MDAlert color="info" dismissible={false}>
            <Grid item container direction="column" spacing={2}>
              <Grid item xs>
                <MDTypography
                  component="span"
                  variant="caption"
                  color="inherit"
                  fontWeight="medium"
                >
                  {address}:
                </MDTypography>
                <MDTypography component="p" variant="caption" color="inherit">
                  {body}
                </MDTypography>
              </Grid>
              <Grid item>
                <MDTypography component="p" variant="caption" color="inherit">
                  {date}
                </MDTypography>
              </Grid>
            </Grid>
          </MDAlert>
        </Grid>
      </MDBox>
    )
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} lg={6}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant="h5">Chat</MDTypography>
                <MDBox pt={3} px={2.5}>
                  <Grid item>
                    <MDButton
                      variant="outlined"
                      color="info"
                      size="small"
                      onClick={createUpdateTask}
                    >
                      Update
                    </MDButton>
                  </Grid>
                </MDBox>
                <MDBox mt={2}> {renderMessages} </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Thread;
