import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import File from "./File";
import axios from "axios";
import PropTypes from "prop-types";

function ClientStorage({ clientId }) {
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("_token");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/clients/${clientId}/storage`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const files = response.data.files;
        setFiles(files);
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          return;
        } else if (error.response.status === 401) {
          navigate("/sign-in");
        }
      }
    };
    fetchData();
  }, []);

  const renderItems = files.map((file, key) => (
    <File
      key={key}
      filename={file.name}
      date={file.datetime}
      size={file.size}
      clientId={clientId}
    />
  ));

  return (
    <Card sx={{ height: "36vh", overflow: "hidden" }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Storage
        </MDTypography>
      </MDBox>
      <MDBox p={2} sx={{ maxHeight: "calc(100% - 56px)", overflowY: "auto" }}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderItems}
        </MDBox>
      </MDBox>
    </Card>
  );
}

ClientStorage.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default ClientStorage;
