import {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import OnlineKeylogger from "./components/OnlineKeylogger";
import OfflineKeylogger from "./components/OfflineKeylogger";
import {useSnackbar} from "notistack";
import axios from "axios";
import {useNavigate} from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Keylogger | Gate";
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("_token");
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/token`, {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        const jwtToken = response.headers["authorization"];

        if (jwtToken) {
          const parts = jwtToken.split(" ");
          const token = parts[1];
          localStorage.setItem("_token", token);
        } else {
          navigate("/sign-in");
        }
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          enqueueSnackbar("No connection with server", {
            variant: "error",
            persist: true,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          });
        } else if (error.response.status === 401) {
          navigate("/sign-in");
        }
      }
    };
    fetchData();
  }, []);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Keylogger() {
  const defaultTab = 0;
  const savedTab = parseInt(localStorage.getItem('activeTab'), 10);
  const [value, setValue] = useState(isNaN(savedTab) ? defaultTab : savedTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('activeTab', newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={2} mb={1}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="info"
          aria-label="tabs">
          <Tab label="Online" {...a11yProps(0)} />
          <Tab label="Offline" {...a11yProps(1)} />
        </Tabs>
      </MDBox>
      <TabPanel value={value} index={0}>
        <OnlineKeylogger />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OfflineKeylogger />
      </TabPanel>
    </DashboardLayout>
  );
}

export default Keylogger;
