import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import "../../../../styles.css";
import Tooltip from "@mui/material/Tooltip";

function ScreenElement({ socket, id, color, text, contentDescription, x, y, h, w, z_index }) {
  const isPressedRef = useRef(false);
  const timerRef = useRef(null);

  const handleMouseLeave = () => {
    isPressedRef.current = false;
    clearTimeout(timerRef.current);
  };

  const displayText = text || contentDescription || "";

  return (
    <Tooltip title={displayText} placement="top">
      <div
        className="icon"
        style={{
          top: `${y}px`,
          left: `${x}px`,
          height: `${h}px`,
          width: `${w}px`,
          borderColor: color,
          zIndex: z_index,
        }}
        onMouseLeave={handleMouseLeave}
      >
        <div className="icon-label">{displayText}</div>
      </div>
    </Tooltip>
  );
}

ScreenElement.propTypes = {
  socket: PropTypes.object,
  id: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  contentDescription: PropTypes.string.isRequired,
  x: PropTypes.number,
  y: PropTypes.number,
  h: PropTypes.number,
  w: PropTypes.number,
  z_index: PropTypes.number,
};

export default ScreenElement;
