import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

function AllApplications({ rows }) {
  const columns = [
    { Header: "application name", accessor: "name", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  return (
    <MDBox sx={{ height: "37vh", overflow: "hidden" }}>
      <MDBox
        mx={1}
        px={1}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All Applications
        </MDTypography>
      </MDBox>
      <MDBox pt={3} sx={{ maxHeight: "calc(100% - 56px)", overflowY: "auto" }}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
        />
      </MDBox>
    </MDBox>
  );
}

export default AllApplications;
