import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../components/MDButton";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";

function Messages({ clientId }) {
  const [rows, setRows] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const columns = [
    { Header: "name", accessor: "chatName", align: "center" },
    { Header: "phone number", accessor: "phoneNumber", align: "center" },
    { Header: "last message datetime", accessor: "lastMessageDatetime", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("_token");
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/messages/update`, {
          client_id: clientId,
        },
          {
            headers: {
              "Authorization": `Bearer ${token}`
            }
          }
        );
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/messages/chats/${clientId}`, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }
        );

        const jwtToken = response.headers["authorization"];
        if (jwtToken) {
          const parts = jwtToken.split(" ");
          const token = parts[1];
          localStorage.setItem("_token", token);
        } else {
          navigate("/sign-in");
        }

        const chats_data = response.data.chats;

        const formattedRows = chats_data.map((chat) => ({
          chatName: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {chat.name}
            </MDTypography>
          ),
          phoneNumber: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {chat.phone_number}
            </MDTypography>
          ),
          lastMessageDatetime: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {chat.last_message_datetime}
            </MDTypography>
          ),
          action: (
            <MDTypography
              component="a"
              href={`/clients/${clientId}/messages/${chat.id}`}
              variant="caption"
              color="text"
              fontWeight="medium"
            >
              Open
            </MDTypography>
          ),
        }));

        setRows(formattedRows);
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          enqueueSnackbar("No connection with server", {
            variant: "error",
            persist: true,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          });
        } else if (error.response.status === 401) {
          navigate("/sign-in");
        }
      }
    };
    fetchData();

    const intervalId = setInterval(fetchData, 1 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  const createUpdateTask = async () => {
    const token = localStorage.getItem("_token");
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/messages/update`, {
        client_id: clientId,
      },
        {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }
      );
      const jwtToken = response.headers["authorization"];

      if (jwtToken) {
        const parts = jwtToken.split(" ");
        const token = parts[1];
        localStorage.setItem("_token", token);
      } else {
        navigate("/sign-in");
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
  };

  return (
    <Card sx={{ height: "45vh", overflow: "hidden" }}>
      <MDBox
        mx={2}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          Chats
        </MDTypography>
      </MDBox>
      <MDBox pt={3} px={2.5}>
        <Grid item>
          <MDButton variant="outlined" color="info" size="small" onClick={createUpdateTask}>
            Update
          </MDButton>
        </Grid>
      </MDBox>
      <MDBox pt={3} sx={{ maxHeight: "calc(100% - 56px)", overflowY: "auto" }}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />
      </MDBox>
    </Card>
  );
}

Messages.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default Messages;
