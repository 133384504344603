import {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import File from "../File";

function BuildsList() {
  const [builds, setBuilds] = useState([]);
  const [buildId, setBuildId] = useState("");
  let client_id = localStorage.getItem("client_id");

  function generateRandomHex(length) {
    const characters = "abcdef0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  useEffect(() => {
    if (!client_id) {
      client_id = generateRandomHex(8);
      localStorage.setItem("client_id", client_id);
    }

    const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}/builder/apk_list/${client_id}`);

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const builds = data.apk;
      setBuilds(builds);
    };

    return () => {
      ws.close();
    };
  }, []);

  const renderItems = builds.map((file, key) => (
    <File
      key={key}
      filename={file.name}
      date={file.datetime}
      size={file.size}
      clientId={buildId}
    />
  ));

  return (
    <Card>
      <MDBox p={2}>
        <MDTypography variant="h5">Builds:</MDTypography>
      </MDBox>
      <MDBox p={2}>
        {renderItems}
      </MDBox>
    </Card>
  );
}

export default BuildsList;
