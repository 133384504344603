import PropTypes from "prop-types";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";

import MDButton from "../../../../components/MDButton";
import axios from "axios";

function SelfDelete({ clientId }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const selfDelete = async () => {
    try {
      const token = localStorage.getItem("_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/applications/self_delete`,
        {
          client_id: clientId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const jwtToken = response.headers["authorization"];

      if (jwtToken) {
        const parts = jwtToken.split(" ");
        const token = parts[1];
        localStorage.setItem("_token", token);
      } else {
        navigate("/sign-in");
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
  };

  return (
    <MDButton
      component="button"
      variant="outlined"
      color="info"
      size="medium"
      onClick={selfDelete}
      sx={{ width: "100%" }}
    >
      Self Delete
    </MDButton>
  );
}

SelfDelete.defaultProps = {
  shadow: true,
};

SelfDelete.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default SelfDelete;
