import { useState } from "react";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

function Dropdown({ clientId, contactId }) {
  const [dropdown, setDropdown] = useState(null);

  const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
  const closeDropdown = () => setDropdown(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const deleteContact = async () => {
    const token = localStorage.getItem("_token");
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/contacts`, {
        data: {
          client_id: clientId,
          contact_id: contactId
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
      );
      closeDropdown();
      const jwtToken = response.headers["authorization"];

      if (jwtToken) {
        const parts = jwtToken.split(" ");
        const token = parts[1];
        localStorage.setItem("_token", token);
      } else {
        navigate("/sign-in");
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
  };

  const editContact = async () => {
    navigate(`/clients/${clientId}/contacts/${contactId}`);
  };

  const iconStyles = {
    ml: 1,
    fontWeight: "bold",
    transition: "transform 200ms ease-in-out",
  };

  const dropdownIconStyles = {
    transform: dropdown ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };

  return (
    <MDBox>
      <MDButton variant="gradient" color="info" onClick={openDropdown}>
        Actions <Icon sx={dropdownIconStyles}>expand_more</Icon>
      </MDButton>
      <Menu anchorEl={dropdown} open={Boolean(dropdown)} onClose={closeDropdown}>
        <MenuItem onClick={editContact}>Edit</MenuItem>
        <MenuItem onClick={deleteContact}>Delete</MenuItem>
      </Menu>
    </MDBox>
  );
}

export default Dropdown;
