import PropTypes from "prop-types";
import axios from "axios";
import MDButton from "components/MDButton";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";

function DeleteButton({ clientId }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const deleteClient = async () => {
    const token = localStorage.getItem("_token");
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/clients`, {
        data: {
          client_id: clientId,
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
      );

      const jwtToken = response.headers["authorization"];

      if (jwtToken) {
        const parts = jwtToken.split(" ");
        const token = parts[1];
        localStorage.setItem("_token", token);
      } else {
        navigate("/sign-in");
      }

    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
    window.location.reload()
  };

  return (
    <MDButton
      component="button"
      onClick={deleteClient}
      variant="text"
      color="error"
      fontWeight="medium"
    >
      Delete
    </MDButton>
  );
}

DeleteButton.defaultProps = {
  shadow: true,
};

DeleteButton.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default DeleteButton;
