import React from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import App from "App";
import {MaterialUIControllerProvider} from "context";
import {SnackbarProvider} from "notistack";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <SnackbarProvider maxSnack={5}>
        <App />
      </SnackbarProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
