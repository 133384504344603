import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "../../../../components/MDAlert";

function ClientLogs() {
  const infoLogContent = (name) => (
    <MDTypography variant="body2" color="white">
      A new client{" "}
      <MDTypography component="span" variant="body2" fontWeight="medium" color="white">
        {name}{" "}
      </MDTypography>
      trying to connect to application.
    </MDTypography>
  );

  const successLogContent = (name) => (
    <MDTypography variant="body2" color="white">
      A client{" "}
      <MDTypography component="span" variant="body2" fontWeight="medium" color="white">
        {name}{" "}
      </MDTypography>
      connected successfully.
    </MDTypography>
  );

  const warningLogContent = (name) => (
    <MDTypography variant="body2" color="white">
      A client{" "}
      <MDTypography component="span" variant="body2" fontWeight="medium" color="white">
        {name}{" "}
      </MDTypography>
      has been disconnected from application.
    </MDTypography>
  );

  const errorLogContent = (name) => (
    <MDTypography variant="body2" color="white">
      Something went wrong with client{" "}
      <MDTypography component="span" variant="body2" fontWeight="medium" color="white">
        {name}{" "}
      </MDTypography>
      !
    </MDTypography>
  );

  return (
    <Card sx={{ height: "36vh", overflow: "hidden" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Notifications
          </MDTypography>
        </MDBox>
      </MDBox>
      {/*<MDBox pt={2} px={2} sx={{ maxHeight: "calc(100% - 56px)", overflowY: "auto" }}>*/}
      {/*  <MDAlert color="info" dismissible={false}>*/}
      {/*    {infoLogContent("IP addr: 127.0.0.1")}*/}
      {/*  </MDAlert>*/}
      {/*  <MDAlert color="success" dismissible={false}>*/}
      {/*    {successLogContent("IP addr: 127.0.0.1")}*/}
      {/*  </MDAlert>*/}
      {/*  <MDAlert color="warning" dismissible={false}>*/}
      {/*    {warningLogContent("IP addr: 127.0.0.1")}*/}
      {/*  </MDAlert>*/}
      {/*  <MDAlert color="error" dismissible={false}>*/}
      {/*    {errorLogContent("IP addr: 127.0.0.1")}*/}
      {/*  </MDAlert>*/}
      {/*</MDBox>*/}
    </Card>
  );
}

export default ClientLogs;
