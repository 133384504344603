import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import ContactTypeSelector from "./components/selector";
import MDButton from "components/MDButton";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import MDInput from "components/MDInput";

function ContactCreator() {
  const { clientId } = useParams();

  const [numbers, setNumbers] = useState([{ contact_type: 'TYPE_MOBILE', phone_number: '' }]);
  const [name, setName] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Contact Creator | Gate";
  }, []);

  const changeName = (event) => {
    setName(event.target.value);
  }
  
  const craeteContact = async () => {
    const token = localStorage.getItem("_token");
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/contacts`, {
        client_id: clientId,
        name: name,
        data: numbers
      },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const jwtToken = response.headers["authorization"];

      if (jwtToken) {
        const parts = jwtToken.split(" ");
        const token = parts[1];
        localStorage.setItem("_token", token);
      } else {
        navigate("/sign-in");
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
  };

  const handleContactChange = (index, field, value) => {
    const updatedNumbers = [...numbers];
    updatedNumbers[index] = {
      ...updatedNumbers[index],
      [field]: value,
    };
    setNumbers(updatedNumbers);
  };

  const addNewContact = () => {
    setNumbers((prevNumbers) => [
      ...prevNumbers,
      { contact_type: 'TYPE_MOBILE', phone_number: '' }
    ]);
  };

  const removeLastContact = () => {
    setNumbers((prevNumbers) => prevNumbers.slice(0, -1));
  };

  const saveChanges = () => {
    craeteContact()
  };

  const renderItems = numbers.map((number, index) => (
    <Grid item key={index}>
      <ContactTypeSelector
        contactType={number.contact_type}
        phoneNumber={number.phone_number}
        onChange={(field, value) => handleContactChange(index, field, value)}
      />
    </Grid>
  ));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" alignItems="center">
        <MDBox>
          <MDInput
            type="text"
            label="Contact Name"
            value={name}
            onChange={changeName}
          />
        </MDBox>
      </MDBox>
      <MDBox mt={2}>
        <Grid container direction="column" spacing={2}>
          {renderItems}
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <IconButton
          aria-label="add"
          color="info"
          size="medium"
          onClick={addNewContact}
        >
          <AddIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          color="error"
          size="medium"
          onClick={removeLastContact}
        >
          <DeleteIcon />
        </IconButton>
      </MDBox>
      <MDBox mt={2}>
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <MDButton
              component="button"
              variant="outlined"
              color="success"
              size="small"
              onClick={saveChanges}
            >
              Create
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ContactCreator;
