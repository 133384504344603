/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import axios from "axios";
import MDButton from "../../../components/MDButton";
import {useSnackbar} from "notistack";

function Messages() {
  const { clientId } = useParams();
  const [rows, setRows] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const columns = [
    { Header: "chat id", accessor: "chatId", align: "center" },
    { Header: "name", accessor: "chatName", align: "center" },
    { Header: "phone number", accessor: "phoneNumber", align: "center" },
    { Header: "last message datetime", accessor: "lastMessageDatetime", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  useEffect(() => {
    document.title = "Chats | Gate";
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("_token");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/messages/chats/${clientId}`, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }
        );

        const jwtToken = response.headers["authorization"];
        if (jwtToken) {
          const parts = jwtToken.split(" ");
          const token = parts[1];
          localStorage.setItem("_token", token);
        } else {
          navigate("/sign-in");
        }

        const chats_data = response.data.chats;

        const formattedRows = chats_data.map((chat) => ({
          chatId: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {chat.id}
            </MDTypography>
          ),
          chatName: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {chat.name}
            </MDTypography>
          ),
          phoneNumber: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {chat.phone_number}
            </MDTypography>
          ),
          lastMessageDatetime: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {chat.last_message_datetime}
            </MDTypography>
          ),
          action: (
            <MDTypography
              component="a"
              href={`/clients/${clientId}/messages/${chat.id}`}
              variant="caption"
              color="text"
              fontWeight="medium"
            >
              Open
            </MDTypography>
          ),
        }));

        setRows(formattedRows);
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          enqueueSnackbar("No connection with server", {
            variant: "error",
            persist: true,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          });
        } else if (error.response.status === 401) {
          navigate("/sign-in");
        }
      }
    };
    fetchData();
  }, []);

  const createUpdateTask = async () => {
    const token = localStorage.getItem("_token");
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/messages/update`, {
        client_id: clientId,
      },
        {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }
      );
      const jwtToken = response.headers["authorization"];

      if (jwtToken) {
        const parts = jwtToken.split(" ");
        const token = parts[1];
        localStorage.setItem("_token", token);
      } else {
        navigate("/sign-in");
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Chats
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={2.5}>
                <Grid item>
                  <MDButton variant="outlined" color="info" size="small" onClick={createUpdateTask}>
                    Update
                  </MDButton>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Messages;
