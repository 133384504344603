import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import "../../../styles.css";
import ScreenElement from "./components/ScreenElement";
import MDButton from "components/MDButton";
import {useSnackbar} from "notistack";
import axios from "axios";
import MDInput from "../../../components/MDInput";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

function Reader() {
  const { clientId } = useParams();
  const [socket, setSocket] = useState(null);
  const [elements, setElements] = useState([]);
  const [orientation, setOrientation] = useState("portrait");
  const [screenHeight, setScreenHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [blockScreenText, setBlockScreenText] = useState("");
  const [isScreenUnlock, setIsScreenLock] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Reader | Gate";
  }, []);

  useEffect(() => {
        if (socket) {
            const handleKeyDown = (event) => {
                switch (event.key) {
                    case 'ArrowUp':
                        event.preventDefault();
                        sendWebSocketMessage({ event: 'action', type: 'SWIPE', args: ['up'] });
                        break;
                    case 'ArrowDown':
                        event.preventDefault();
                        sendWebSocketMessage({ event: 'action', type: 'SWIPE', args: ['down'] });
                        break;
                    case 'ArrowLeft':
                        event.preventDefault();
                        sendWebSocketMessage({ event: 'action', type: 'SWIPE', args: ['left'] });
                        break;
                    case 'ArrowRight':
                        event.preventDefault();
                        sendWebSocketMessage({ event: 'action', type: 'SWIPE', args: ['right'] });
                        break;
                    default:
                        break;
                }
            };

            window.addEventListener('keydown', handleKeyDown);

            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [socket]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("_token");
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/token`, {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        const jwtToken = response.headers["authorization"];

        if (jwtToken) {
          const parts = jwtToken.split(" ");
          const token = parts[1];
          localStorage.setItem("_token", token);
        } else {
          navigate("/sign-in");
        }
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          enqueueSnackbar("No connection with server", {
            variant: "error",
            persist: true,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          });
        } else if (error.response.status === 401) {
          navigate("/sign-in");
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}/reader/${clientId}`);
    setSocket(ws);

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      setElements(message.elements);
      setOrientation(message.orientation);
      setIsScreenLock(message.screen_status);
      setScreenHeight(message.screen_height);
      setScreenWidth(message.screen_width);
    };

    return () => {
      createStopTask();
      ws.close();
    };
  }, []);

  const createStartTask = async () => {
    const token = localStorage.getItem("_token");
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/reader`, {
        client_id: clientId,
        action: "start",
      },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const jwtToken = response.headers["authorization"];

      if (jwtToken) {
        const parts = jwtToken.split(" ");
        const token = parts[1];
        localStorage.setItem("_token", token);
      } else {
        navigate("/sign-in");
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
  };

  const createStopTask = async () => {
    const token = localStorage.getItem("_token");
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/reader`, {
        client_id: clientId,
        action: "stop",
      },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const jwtToken = response.headers["authorization"];

      if (jwtToken) {
        const parts = jwtToken.split(" ");
        const token = parts[1];
        localStorage.setItem("_token", token);
        setElements([])
      } else {
        navigate("/sign-in");
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
  };

  const sendWebSocketMessage = (message) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message));
    } else {
      console.error("WebSocket is not open.");
    }
  };

  const lockClick = () => {
    sendWebSocketMessage({ event: "action", type: "LOCK", args: [] });
  };

  const unlockClick = () => {
    sendWebSocketMessage({ event: "action", type: "UNLOCK", args: [] });
  };

  const swipeUpClick = () => {
    sendWebSocketMessage({ event: "action", type: "SWIPE", args: ["up"] });
  };

  const swipeDownClick = () => {
    sendWebSocketMessage({ event: "action", type: "SWIPE", args: ["down"] });
  };

  const swipeLeftClick = () => {
    sendWebSocketMessage({ event: "action", type: "SWIPE", args: ["left"] });
  };

  const swipeRightClick = () => {
    sendWebSocketMessage({ event: "action", type: "SWIPE", args: ["right"] });
  };

  const sendText = () => {
    sendWebSocketMessage({ event: "action", type: "INPUT_TEXT", args: [inputValue] });
    setInputValue("");
  };

  const recentAppsClick = () => {
    sendWebSocketMessage({ event: "action", type: "RECENTS", args: [] });
  };

  const homeClick = () => {
    sendWebSocketMessage({ event: "action", type: "HOME", args: [] });
  };

  const backClick = () => {
    sendWebSocketMessage({ event: "action", type: "BACK", args: [] });
  };

  const blockClick = () => {
    sendWebSocketMessage({ event: "action", type: "HIDE_SCREEN", args: [blockScreenText, "1"] });
    setBlockScreenText("");
  };

  const releaseClick = () => {
    sendWebSocketMessage({ event: "action", type: "SHOW_SCREEN", args: [] });
  };

  return (
    <DashboardLayout>
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={4}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant="h5">Control panel</MDTypography>
              </MDBox>
              <MDBox pb={2}>
                <MDTypography component="span" variant="body2" fontSize="meduim" ml={3} mr={3}>
                  Screen Status:
                </MDTypography>
                {!isScreenUnlock ? (
                  <Icon fontSize="small" sx={{ color: "red" }}>
                    lock
                  </Icon>
                ) : (
                  <Icon fontSize="small" sx={{ color: "green" }}>
                    lock_open
                  </Icon>
                )}
              </MDBox>
              <Grid container direction="column" justifyContent="center">
                <Grid item>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={4}>
                      <MDBox pb={2}>
                        <MDButton
                          component="button"
                          variant="outlined"
                          color="info"
                          size="small"
                          sx={{ width: "80%" }}
                          onClick={createStartTask}
                        >
                          Start
                        </MDButton>
                      </MDBox>
                    </Grid>
                    <Grid item xs={4}>
                      <MDBox pb={2}>
                        <MDButton
                          component="button"
                          variant="outlined"
                          color="info"
                          size="small"
                          sx={{ width: "80%" }}
                          onClick={createStopTask}
                        >
                          Stop
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={4}>
                      <MDBox pb={2}>
                        <MDButton
                          component="button"
                          variant="outlined"
                          color="info"
                          size="small"
                          sx={{ width: "80%" }}
                          onClick={lockClick}
                        >
                          Lock
                        </MDButton>
                      </MDBox>
                    </Grid>
                    <Grid item xs={4}>
                      <MDBox pb={2}>
                        <MDButton
                          component="button"
                          variant="outlined"
                          color="info"
                          size="small"
                          sx={{ width: "80%" }}
                          onClick={unlockClick}
                        >
                          Unlock
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={4}>
                      <MDButton
                        component="button"
                        variant="outlined"
                        color="info"
                        size="small"
                        sx={{ width: "80%" }}
                        onClick={swipeUpClick}
                      >
                        Swipe up
                      </MDButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="center" spacing={4}>
                    <Grid item xs={4}>
                      <MDButton
                        component="button"
                        variant="outlined"
                        color="info"
                        size="small"
                        sx={{ width: "80%" }}
                        onClick={swipeLeftClick}
                      >
                        Swipe left
                      </MDButton>
                    </Grid>
                    <Grid item xs={4}>
                      <MDButton
                        component="button"
                        variant="outlined"
                        color="info"
                        size="small"
                        sx={{ width: "80%" }}
                        onClick={swipeRightClick}
                      >
                        Swipe right
                      </MDButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={4}>
                      <MDButton
                        component="button"
                        variant="outlined"
                        color="info"
                        size="small"
                        sx={{ width: "80%" }}
                        onClick={swipeDownClick}
                      >
                        Swipe down
                      </MDButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center">
                  <Grid item xs={3}>
                    <MDBox pb={2}>
                      <MDButton
                        component="button"
                        variant="outlined"
                        color="info"
                        size="small"
                        sx={{ width: "80%" }}
                        onClick={recentAppsClick}
                      >
                        Recents
                      </MDButton>
                    </MDBox>
                  </Grid>
                  <Grid item xs={3}>
                    <MDBox pb={2}>
                      <MDButton
                        component="button"
                        variant="outlined"
                        color="info"
                        size="small"
                        sx={{ width: "80%" }}
                        onClick={homeClick}
                      >
                        Home
                      </MDButton>
                    </MDBox>
                  </Grid>
                  <Grid item xs={3}>
                    <MDBox pb={2}>
                      <MDButton
                        component="button"
                        variant="outlined"
                        color="info"
                        size="small"
                        sx={{ width: "80%" }}
                        onClick={backClick}
                      >
                        Back
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={4}>
                      <MDBox pb={2}>
                        <MDBox mt={2}>
                          <MDInput
                              type="text"
                              label="Enter text on block screen"
                              value={blockScreenText}
                              onChange={(e) => setBlockScreenText(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  blockClick();
                                }
                              }}
                          />
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={4}>
                      <MDBox pb={2}>
                        <Tooltip title="Block screen (activate black screen)" placement="top">
                          <MDButton
                            component="button"
                            variant="outlined"
                            color="info"
                            size="small"
                            sx={{ width: "80%" }}
                            onClick={blockClick}
                          >
                            Block
                          </MDButton>
                        </Tooltip>
                      </MDBox>
                    </Grid>
                    <Grid item xs={4}>
                      <MDBox pb={2}>
                        <Tooltip title="Release screen (deactivate black screen)" placement="top">
                          <MDButton
                            component="button"
                            variant="outlined"
                            color="info"
                            size="small"
                            sx={{ width: "80%" }}
                            onClick={releaseClick}
                          >
                            Release
                          </MDButton>
                        </Tooltip>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={4}>
                      <MDBox pb={2}>
                        <MDBox mt={2}>
                          <MDInput
                            type="text"
                            label="Enter text"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                sendText();
                              }
                            }}
                          />
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={4}>
                      <MDBox pb={2}>
                        <MDButton
                          component="button"
                          variant="outlined"
                          color="info"
                          size="small"
                          sx={{ width: "100%" }}
                          onClick={sendText}
                        >
                          Send
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2} >
                <div className={`mobile-screen-${orientation}`}onClick={(event) => {
                  const rect = event.currentTarget.getBoundingClientRect();
                  const x = event.clientX - rect.left;
                  const y = event.clientY - rect.top;
                  sendWebSocketMessage({ event: 'action', type: 'CLICK', args: [x, y], 'params': [screenHeight, screenWidth, orientation] });
                }}>
                  {elements.map((element, index) => (
                    <ScreenElement
                      key={index}
                      socket={socket}
                      id={element.id}
                      color={element.color}
                      text={element.text}
                      contentDescription={element.content_description}
                      x={element.x}
                      y={element.y}
                      h={element.h}
                      w={element.w}
                      z_index={element.z_index}
                    />
                  ))}
                </div>
              </MDBox>
                <Grid container direction="row" justifyContent="center">
                    <Grid item xs={3}>
                        <MDBox pb={2}>
                            <MDButton
                                component="button"
                                variant="outlined"
                                color="info"
                                size="small"
                                sx={{ width: "80%" }}
                                onClick={recentAppsClick}
                            >
                                Recents
                            </MDButton>
                        </MDBox>
                    </Grid>
                    <Grid item xs={3}>
                        <MDBox pb={2}>
                            <MDButton
                                component="button"
                                variant="outlined"
                                color="info"
                                size="small"
                                sx={{ width: "80%" }}
                                onClick={homeClick}
                            >
                                Home
                            </MDButton>
                        </MDBox>
                    </Grid>
                    <Grid item xs={3}>
                        <MDBox pb={2}>
                            <MDButton
                                component="button"
                                variant="outlined"
                                color="info"
                                size="small"
                                sx={{ width: "80%" }}
                                onClick={backClick}
                            >
                                Back
                            </MDButton>
                        </MDBox>
                    </Grid>
                </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Reader;
