import {useState} from "react";
import {useNavigate} from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import {useSnackbar} from "notistack";
import axios from "axios";

function PlatformSettings() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const changePasswordClick = async () => {
    if (oldPassword === newPassword) {
      enqueueSnackbar("The new password must be different from the old password", {
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }
  
    if (newPassword !== repeatNewPassword) {
      enqueueSnackbar("New passwords do not match", {
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }

    const token = localStorage.getItem("_token");
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/settings/change_password`, {
        old_password: oldPassword,
        new_password: newPassword,
      },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      } else if (error.response.status === 400) {
        enqueueSnackbar("Password is invalid", {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      }
    }
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Account settings
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          Change password
        </MDTypography>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={2}>
            <MDInput
              type="password"
              label="Old password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={1}>
            <MDInput
              type="password"
              label="New password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={1}>
            <MDInput
              type="password"
              label="Repeat new password"
              value={repeatNewPassword}
              onChange={(e) => setRepeatNewPassword(e.target.value)}
            />
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center">
          <MDBox mt={2}>
            <MDButton
              variant="outlined"
              color="info"
              size="medium"
              onClick={changePasswordClick}
            >
              Change password
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default PlatformSettings;
