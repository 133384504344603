import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import axios from "axios";
import MDBadge from "../../components/MDBadge";
import { useSnackbar } from "notistack";
import DeleteButton from "./components/DeleteButton";
import Tooltip from "@mui/material/Tooltip";
import Battery50Icon from '@mui/icons-material/Battery50';
import BatteryCharging50Icon from '@mui/icons-material/BatteryCharging50';

function Clients() {
  const [rows, setRows] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Clients | Gate";
  }, []);

  const IpAddressInfo = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="center">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const columns = [
    { Header: "user id", accessor: "user_id", align: "center" },
    { Header: "created at", accessor: "created_at", align: "center" },
    { Header: "vendor", accessor: "vendor", align: "center" },
    { Header: "android version", accessor: "androidVersion", align: "center" },
    { Header: "battery", accessor: "batteryPercent", align: "center" },
    { Header: "ip address", accessor: "ip", align: "center" },
    { Header: "network type", accessor: "networkType", align: "center" },
    { Header: "vpn status", accessor: "vpnStatus", align: "center" },
    { Header: "screen status", accessor: "screenStatus", align: "center" },
    { Header: "connect status", accessor: "connectStatus", align: "center" },
    { Header: "Onboarding Status", accessor: "onboardingStatus", align: "center" },
    { Header: "password", accessor: "password", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const shortUserId = (userId) => {
    return userId.slice(0, 3) + '...' + userId.slice(-3);
  }

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("_token");
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/clients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const jwtToken = response.headers["authorization"];

        if (jwtToken) {
          const parts = jwtToken.split(" ");
          const token = parts[1];
          localStorage.setItem("_token", token);
        } else {
          navigate("/sign-in");
        }

        const clients_data = response.data.clients;

        const formattedRows = clients_data.map((client) => ({
          user_id: (
            <Tooltip title={client.id} placement="top">
              <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
                {shortUserId(client.id)}
              </MDTypography>
            </Tooltip>
          ),
          created_at: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {client.created_at}
            </MDTypography>
          ),
          vendor: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {client.vendor}
            </MDTypography>
          ),
          androidVersion: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {client.version}
            </MDTypography>
          ),
          batteryPercent: (
            client.connect_status ? (
              <MDBox ml={-1}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={8}>
                    <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
                      {client.battery_percent === -1 ? "Unknown" : `${client.battery_percent} %`}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={4}>
                    {client.battery_state ? (
                      <BatteryCharging50Icon color="success" fontSize="medium" />
                    ) : (
                      <Battery50Icon color="warning" fontSize="medium" />
                    )}
                  </Grid>
                </Grid>
              </MDBox>
            ) : (
              <MDBadge
                badgeContent="Unknown"
                color="error"
                variant="gradient"
                size="sm"
              />
            )
          ),
          ip: (
            <IpAddressInfo
              title={client.ip_address ? client.ip_address : "Unknown"}
              description={client.country ? client.country : "Unknown"}
            />
          ),
          networkType: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {client.network_type ? client.network_type : "Unknown"}
            </MDTypography>
          ),
          vpnStatus: (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent={
                  client.connect_status ? (client.vpn_status ? "Active" : "Inactive") : "Unknown"
                }
                color={client.connect_status ? (client.vpn_status ? "success" : "dark") : "error"}
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ),
          screenStatus: (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent={
                  client.connect_status ? (client.screen_status ? "Unlock" : "Lock") : "Unknown"
                }
                color={
                  client.connect_status ? (client.screen_status ? "success" : "dark") : "error"
                }
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ),
          connectStatus: (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent={client.connect_status ? "Connected" : "Disconnected"}
                color={client.connect_status ? "success" : "error"}
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ),
          onboardingStatus: (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent={
                  client.connect_status ? (client.is_onboarding_complete ? "Success" : "Fail") : "Unknown"
                }
                color={
                  client.connect_status ? (client.is_onboarding_complete ? "success" : "error") : "error"
                }
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ),
          password: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {client.password ? client.password : "N / A"}
            </MDTypography>
          ),
          action: client.connect_status ? (
            <MDTypography
              component="a"
              href={`/clients/${client.id}`}
              variant="caption"
              color="text"
              fontWeight="medium"
            >
              Control
            </MDTypography>
          ) : (
            <DeleteButton clientId={client.id} />
          ),
        }));

        setRows(formattedRows);
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          enqueueSnackbar("No connection with server", {
            variant: "error",
            persist: true,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          });
        } else if (error.response.status === 401) {
          navigate("/sign-in");
        }
      }
    };
    fetchData();

    const intervalId = setInterval(fetchData, 5 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={1}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Clients
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Clients;
