import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "../../../../components/MDButton";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import Grid from "@mui/material/Grid";
import BootstrapDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function TwoFactorAuthSettings() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [tfa_status, setTfaStatus] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [secret, setSecret] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("_token");
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/settings`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        setTfaStatus(response.data.tfa_status);
        console.log(`2FA status: ${response.data.tfa_status}`);

      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          enqueueSnackbar("No connection with server", {
            variant: "error",
            persist: true,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          });
        } else if (error.response.status === 401) {
          navigate("/sign-in");
        }
      }
    };
    fetchData();
  }, []);
  
  const acivate2faButtonClick = async () => {
    const token = localStorage.getItem("_token");
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/settings/2fa/activate`, {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const qrCode = response.data.qr_code;
      const secret = response.data.secret;
      setQrCodeData(qrCode);
      setSecret(secret);
      setOpenDialog(true);

    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      } else if (error.response.status === 400) {
        enqueueSnackbar("Password is invalid", {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      }
    }
  };

  const handleCloseDialog = () => {
    setTfaStatus(true);
    setOpenDialog(false);
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Two-factor authentication
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          2FA Status
        </MDTypography>
        {tfa_status ? (
          <MDBox display="flex" alignItems="center" ml={7}>
            <MDBox mt={2}>
              <MDButton variant="gradient" color="success" size="small">
                Active
              </MDButton>
            </MDBox>
          </MDBox>
        ) : (
          <MDBox>
            <MDBox display="flex" alignItems="center" ml={6.25}>
              <MDBox mt={2}>
                <MDButton variant="gradient" color="error" size="small">
                  Inactive
                </MDButton>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center">
              <MDBox mt={2} ml={3.5}>
                <MDButton variant="outlined" color="info" size="medium" onClick={acivate2faButtonClick}>
                  Activate 2FA
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
      <BootstrapDialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle sx={{ m: 0, p: 2 }} color="secondary">
          Scan this QR code
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {qrCodeData ? (
            <Grid container direction="column" spacing={1} justifyContent="center" alignItems="center">
              <Grid item xs={2} container justifyContent="center" alignItems="center">
                <img src={`data:image/png;base64,${qrCodeData}`} alt="QR Code" width="256" height="256" />
              </Grid>
              <Grid item>
                <MDTypography component="span" variant="outlined" color="secondary" fontWeight="medium">
                  {secret}
                </MDTypography>
              </Grid>
            </Grid>
          ) : (
            <MDTypography>No QR code available</MDTypography>
          )}
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseDialog}>Close</MDButton>
        </DialogActions>
      </BootstrapDialog>
    </Card>
  );
}

export default TwoFactorAuthSettings;
