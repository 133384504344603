import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import MDButton from "components/MDButton";
import {useSnackbar} from "notistack";
import File from "./components/File";

function FileManager() {
  const { clientId } = useParams();
  const [files, setFiles] = useState([]);
  const [socket, setSocket] = useState(null);
  const [currentFolder, setCurrentFolder] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "File Manager | Gate";
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("_token");
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/token`, {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        const jwtToken = response.headers["authorization"];

        if (jwtToken) {
          const parts = jwtToken.split(" ");
          const token = parts[1];
          localStorage.setItem("_token", token);
        } else {
          navigate("/sign-in");
        }
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          enqueueSnackbar("No connection with server", {
            variant: "error",
            persist: true,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          });
        } else if (error.response.status === 401) {
          navigate("/sign-in");
        }
      }
    };
    fetchData();
  }, []);

  const sendWebSocketMessage = (message) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message));
    } else {
      console.error("WebSocket is not open.");
    }
  };

  const createStartTask = async () => {
    const token = localStorage.getItem("_token");
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/file_manager`,
        {
          client_id: clientId,
          action: "start",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
  };

  const createStopTask = async () => {
    const token = localStorage.getItem("_token");
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/file_manager`,
        {
          client_id: clientId,
          action: "stop",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      }
    }
  };

  const renderItems = files.map((file, key) => (
    <File
      key={key}
      filename={file.name}
      fileType={file.type}
      date={file.datetime}
      size={file.size}
      currentPath={currentFolder}
      isFolder={file.is_folder}
      onMessage={sendWebSocketMessage}
    />
  ));

  useEffect(() => {
    const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}/file_manager/${clientId}`);
    setSocket(ws);

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log(data);
      if (data.event === "file_download") {
        enqueueSnackbar(data.data.message, {
          variant: data.data.type,
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      } else {
        setCurrentFolder(data.current_folder);
        setFiles(data.files);
      }
    };

    return () => {
      createStopTask();
      ws.close();
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={2}>
            <Card sx={{ height: "80vh", overflow: "hidden" }}>
              <MDBox p={2}>
                <MDTypography variant="h5">Control</MDTypography>
              </MDBox>
              <Grid container spacing={1} direction="row">
                <Grid item>
                  <MDBox p={2}>
                    <MDButton
                      component="button"
                      variant="outlined"
                      color="info"
                      size="small"
                      sx={{ width: "100%" }}
                      onClick={createStartTask}
                    >
                      Start
                    </MDButton>
                  </MDBox>
                </Grid>
                <Grid item>
                  <MDBox p={2}>
                    <MDButton
                      component="button"
                      variant="outlined"
                      color="info"
                      size="small"
                      sx={{ width: "100%" }}
                      onClick={createStopTask}
                    >
                      Stop
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox sx={{ height: "80vh", overflow: "hidden" }}>
                <MDBox
                  mt={2}
                  mx={2}
                  py={1}
                  px={2}
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="info"
                  align="center"
                >
                  <MDTypography variant="h6" color="white">
                    {currentFolder}
                  </MDTypography>
                </MDBox>
                <MDBox p={2} sx={{ maxHeight: "calc(100% - 56px)", overflowY: "auto" }}>
                  {renderItems}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default FileManager;
