import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MDInput from "components/MDInput";

function ContactTypeSelector({ contactType, phoneNumber, onChange }) {
    const [_contactType, setContactType] = useState(contactType);
    const [_phoneNumber, setPhoneNumber] = useState(phoneNumber);

    useEffect(() => {
        setContactType(contactType);
        setPhoneNumber(phoneNumber);
    }, [contactType, phoneNumber]);

    const handleContactTypeChange = (event) => {
        const newContactType = event.target.value;
        setContactType(newContactType);
        onChange("contact_type", newContactType);
    };

    const handlePhoneNumberChange = (e) => {
        const newPhoneNumber = e.target.value;
        setPhoneNumber(newPhoneNumber);
        onChange("phone_number", newPhoneNumber);
    };

    return (
        <MDBox>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <Select
                            id="demo-simple-select"
                            value={_contactType}
                            onChange={handleContactTypeChange}
                            sx={{
                                height: 44,
                                fontSize: 14,
                            }}
                        >
                            <MenuItem value={"TYPE_ASSISTANT"}>TYPE_ASSISTANT</MenuItem>
                            <MenuItem value={"TYPE_CALLBACK"}>TYPE_CALLBACK</MenuItem>
                            <MenuItem value={"TYPE_CAR"}>TYPE_CAR</MenuItem>
                            <MenuItem value={"TYPE_COMPANY_MAIN"}>TYPE_COMPANY_MAIN</MenuItem>
                            <MenuItem value={"TYPE_FAX_HOME"}>TYPE_FAX_HOME</MenuItem>
                            <MenuItem value={"TYPE_FAX_WORK"}>TYPE_FAX_WORK</MenuItem>
                            <MenuItem value={"TYPE_HOME"}>TYPE_HOME</MenuItem>
                            <MenuItem value={"TYPE_ISDN"}>TYPE_ISDN</MenuItem>
                            <MenuItem value={"TYPE_MAIN"}>TYPE_MAIN</MenuItem>
                            <MenuItem value={"TYPE_MMS"}>TYPE_MMS</MenuItem>
                            <MenuItem value={"TYPE_MOBILE"}>TYPE_MOBILE</MenuItem>
                            <MenuItem value={"TYPE_OTHER"}>TYPE_OTHER</MenuItem>
                            <MenuItem value={"TYPE_OTHER_FAX"}>TYPE_OTHER_FAX</MenuItem>
                            <MenuItem value={"TYPE_PAGER"}>TYPE_PAGER</MenuItem>
                            <MenuItem value={"TYPE_RADIO"}>TYPE_RADIO</MenuItem>
                            <MenuItem value={"TYPE_TELEX"}>TYPE_TELEX</MenuItem>
                            <MenuItem value={"TYPE_TTY_TDD"}>TYPE_TTY_TDD</MenuItem>
                            <MenuItem value={"TYPE_WORK"}>TYPE_WORK</MenuItem>
                            <MenuItem value={"TYPE_WORK_MOBILE"}>TYPE_WORK_MOBILE</MenuItem>
                            <MenuItem value={"TYPE_WORK_PAGER"}>TYPE_WORK_PAGER</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <MDBox display="flex" alignItems="center">
                        <MDBox>
                            <MDInput
                                type="text"
                                label="Phone Number"
                                value={_phoneNumber}
                                onChange={handlePhoneNumberChange}
                            />
                        </MDBox>
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default ContactTypeSelector;
