import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import TimelineItem from "examples/Timeline/TimelineItem";
import axios from "axios";

function Logs() {
  const [logs, setLogs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Logs | Gate";
  }, []);

  useEffect(() => {
    async function fetchLogs() {
      const token = localStorage.getItem("_token");
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/logs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const jwtToken = response.headers["authorization"];
        if (jwtToken) {
          const parts = jwtToken.split(" ");
          const token = parts[1];
          localStorage.setItem("_token", token);
        } else {
          navigate("/sign-in");
        }

        const data = response.data.logs;
        if (data) {
          setLogs(data);
        }
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          return;
        } else if (error.response.status === 401) {
          navigate("/sign-in");
        }
      }
    }
    fetchLogs();
  }, []);
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={6}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant="h5">Logs</MDTypography>
              </MDBox>
              <MDBox p={2}>
                {logs.map((log, index) => (
                  <TimelineItem
                    key={index}
                    color={log.type}
                    icon="notifications"
                    title={log.message}
                    clientId={log.client_id}
                    dateTime={log.datetime}
                    lastItem={index === logs.length - 1}
                  />
                ))}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Logs;
