import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";

function ClientInfoCard({ title, info, location, shadow }) {
  const general_labels = [];
  const general_values = [];
  const location_labels = [];
  const location_values = [];

  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      general_labels.push(newElement);
    } else {
      general_labels.push(el);
    }
  });

  Object.values(info).forEach((el) => general_values.push(el));

  const renderGeneral = general_labels.map((label, key) => (
    <MDBox key={label} display="flex" py={0.5} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{general_values[key]}
      </MDTypography>
    </MDBox>
  ));

  Object.keys(location).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      location_labels.push(newElement);
    } else {
      location_labels.push(el);
    }
  });

  Object.values(location).forEach((el) => location_values.push(el));

  const renderLocation = location_labels.map((label, key) => (
    <MDBox key={label} display="flex" py={0.5} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{location_values[key]}
      </MDTypography>
    </MDBox>
  ));
  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox opacity={0.3}>
          <Divider />
        </MDBox>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                General
              </MDTypography>
            </MDBox>
            <MDBox>{renderGeneral}</MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Location
              </MDTypography>
            </MDBox>
            <MDBox>{renderLocation}</MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

ClientInfoCard.defaultProps = {
  shadow: true,
};

ClientInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  info: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  shadow: PropTypes.bool,
};

export default ClientInfoCard;
