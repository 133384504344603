import React, { useState } from 'react';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import axios from 'axios';
import { useSnackbar } from "notistack";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MDButton from 'components/MDButton';
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";

function BuildSettings() {
  const [formData, setFormData] = useState({
    apkName: '',
    packageName: '',
    version: '',
    pushHeader: '',
    pushBody: '',
    appHeader: '',
    buttonText: '',
    noInternetText: '',
    mainDescription: '',
    onboardingText: '',
    onboardingDescription: '',
    webViewUrl: '',
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [filePreview, setFilePreview] = useState('');
  const [valid, setValid] = useState(true);
  const [isLatestActive, setIsLatestActive] = useState(true);

  const isFormValid = Object.values(formData).every(field => field.trim() !== '') && selectedFile && valid;

  const handleLatestMode = () => {
    setIsLatestActive(!isLatestActive);
  }

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1 * 1024 * 1024) {
        enqueueSnackbar("The file exceeds the maximum size of 1 MB", {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
        return;
      }
      if (file.type !== 'image/png') {
        enqueueSnackbar("Only PNG format is allowed", {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
        return;
      }

      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        img.onload = () => {
          if (img.width !== 512 || img.height !== 512) {
            enqueueSnackbar("The image must be 512x512 pixels in size", {
              variant: "error",
              anchorOrigin: { horizontal: "right", vertical: "bottom" },
            });
            return;
          }
          setSelectedFile(file);
          setFilePreview(e.target.result);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValid(true);
    if (name === "webViewUrl") {
      const isUrlValid = isValidUrl(value);
      if (!isUrlValid) {
        setValid(false);
      }
    }
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpload = () => {
    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      formDataToSend.append(key, value);
    }
    if (selectedFile) {
      formDataToSend.append('file', selectedFile);
    }
    formDataToSend.append('latest_version', isLatestActive);
    const token = localStorage.getItem("_token");

    axios.post(`${process.env.REACT_APP_API_URL}/builder`, formDataToSend, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${token}`
      }
    })
      .then(response => {
        enqueueSnackbar("File upload sucess", {
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      })
      .catch(error => {
        enqueueSnackbar("Error when uploading a file", {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      });
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Build settings
        </MDTypography>
      </MDBox>
      <Grid container spacing={2}>
        <Grid item xs>
          <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
            <MDTypography
              variant="caption"
              fontWeight="bold"
              color="text"
              textTransform="uppercase"
            >
              APK
            </MDTypography>
            <MDBox display="flex" alignItems="center" mb={0.5}>
              <MDBox mt={2}>
                <MDInput
                  name="apkName"
                  type="text"
                  label="APK Name"
                  onChange={handleInputChange}
                  value={formData.apkName}
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5}>
              <MDBox mt={1}>
                <MDInput
                  name="packageName"
                  type="text"
                  label="Package Name"
                  placeholder="com.app.example"
                  onChange={handleInputChange}
                  value={formData.packageName}
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5}>
              <MDBox mt={1}>
                <MDInput
                  name="version"
                  type="text"
                  label="Version"
                  placeholder="1.0.0"
                  onChange={handleInputChange}
                  value={formData.version}
                />
              </MDBox>
            </MDBox>
            <MDBox mt={4}>
              <MDTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                textTransform="uppercase"
              >
                Push
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5}>
              <MDBox mt={1}>
                <MDInput
                  name="pushHeader"
                  type="text"
                  label="Header"
                  onChange={handleInputChange}
                  value={formData.pushHeader}
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5}>
              <MDBox mt={1}>
                <MDInput
                  name="pushBody"
                  type="text"
                  label="Body"
                  onChange={handleInputChange}
                  value={formData.pushBody}
                />
              </MDBox>
            </MDBox>
            <MDBox mt={2}>
              <Tooltip title="Use the latest (not stable) APK version." placement="top">
                <MDTypography variant="h6">Use latest version:</MDTypography>
                <Switch checked={isLatestActive} onChange={handleLatestMode} />
              </Tooltip>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs>
          <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
            <MDTypography
              variant="caption"
              fontWeight="bold"
              color="text"
              textTransform="uppercase"
            >
              application
            </MDTypography>
            <MDBox display="flex" alignItems="center" mb={0.5}>
              <MDBox mt={2}>
                {valid ? (
                  <MDInput
                    name="webViewUrl"
                    type="text"
                    label="Web View URL"
                    placeholder="https://example.com"
                    onChange={handleInputChange}
                    value={formData.webViewUrl}
                  />
                ) : (
                  <MDInput
                    error
                    name="webViewUrl"
                    type="text"
                    label="Web View URL"
                    placeholder="https://example.com"
                    onChange={handleInputChange}
                    value={formData.webViewUrl}
                  />
                )}
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5}>
              <MDBox mt={1}>
                <MDInput
                  name="appHeader"
                  type="text"
                  label="Header"
                  placeholder=""
                  onChange={handleInputChange}
                  value={formData.appHeader}
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5}>
              <MDBox mt={1}>
                <MDInput
                  name="buttonText"
                  type="text"
                  label="Button text"
                  placeholder="Next"
                  onChange={handleInputChange}
                  value={formData.buttonText}
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5}>
              <MDBox mt={1}>
                <MDInput
                  name="noInternetText"
                  type="text"
                  label="No internet text"
                  placeholder="No internet connection"
                  onChange={handleInputChange}
                  value={formData.noInternetText}
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5}>
              <MDBox mt={1}>
                <MDInput
                  name="mainDescription"
                  type="text"
                  label="Main description"
                  multiline
                  maxRows={4}
                  onChange={handleInputChange}
                  value={formData.mainDescription}
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5}>
              <MDBox mt={1}>
                <MDInput
                  name="onboardingText"
                  type="text"
                  label="Onboarding text"
                  multiline
                  maxRows={4}
                  onChange={handleInputChange}
                  value={formData.onboardingText}
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5}>
              <MDBox mt={1}>
                <MDInput
                  name="onboardingDescription"
                  type="text"
                  label="Onboarding description"
                  multiline
                  maxRows={4}
                  onChange={handleInputChange}
                  value={formData.onboardingDescription}
                />
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      <MDBox display="flex" alignItems="center" mb={0.5}>
        <MDBox mt={2} ml={2} mb={2}>
          <Grid container direction="row" spacing={5}>
            <Grid item>
              <MDInput
                type="file"
                accept=".png"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="file-upload"
              />
              <label htmlFor="file-upload">
                <MDButton variant="outlined" color="info" component="span" startIcon={<CloudUploadIcon />} sx={{ width: "9vw" }}>
                  Select icon
                </MDButton>
              </label>
            </Grid>
            <Grid item>
              {selectedFile && (
                <MDBox>
                  {filePreview && (
                    <MDBox>
                      <img
                        src={filePreview}
                        alt="Preview"
                        style={{ width: '40px', height: '40px' }}
                      />
                    </MDBox>
                  )}
                </MDBox>
              )}
            </Grid>
          </Grid>
          <MDBox>
            {isFormValid && (
              <MDButton variant="outlined" color="info" onClick={handleUpload} sx={{ width: "9vw" }}>
                Build
              </MDButton>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BuildSettings;
