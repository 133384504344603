import {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TimelineItem from "examples/Timeline/TimelineItem";

function Logs() {
  const [logs, setLogs] = useState([]);
  let client_id = localStorage.getItem("client_id");

  function generateRandomHex(length) {
    const characters = "abcdef0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  useEffect(() => {
    if (!client_id) {
      client_id = generateRandomHex(8);
      localStorage.setItem("client_id", client_id);
    }

    const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}/builder_logs/${client_id}`);

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.event === "build_logs") {
        const logs = data.logs
        setLogs([...logs].reverse());
      }
    };

    return () => {
      ws.close();
    };
  }, []);

  return (
    <Card>
      <MDBox p={2}>
        <MDTypography variant="h5">Logs</MDTypography>
      </MDBox>
      <MDBox p={2}>
        {logs.map((log, index) => (
          <TimelineItem
            key={index}
            color={log.type}
            icon="notifications"
            title={log.message}
            clientId={log.build_id}
            dateTime={log.datetime}
            lastItem={index === logs.length - 1}
          />
        ))}
      </MDBox>
    </Card>
  );
}

export default Logs;
